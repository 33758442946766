import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import {BsArrowRightSquare} from 'react-icons/bs'
import { isMobile } from 'react-device-detect'

import { requestLink } from '../../../Components/dashboard/redux/email.link.slice';
import { addMetadata } from '../../../Components/navBar/metadata.slice';
import { Loading } from '../../../Components/loader/loader';
import { FreeTrialAlert } from '../../home/home';
import { DashboardNote, DashboardNoteContainer } from '../../dashboard/Dashboard';

const CTA = styled.button`
    padding: 10px;
    font-size: medium;
    color: white;
    background-color: green;
    border-radius: 8px;
    margin-top: 10px;
    cursor: pointer;
    border: none;
    ${(props) => props.disabled && css`
    cursor: none;
    pointer-events: none;
    color: #98F998;`
  }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    align-items: center;
    justify-content: center;
`

const MiniContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
`

const Text = styled.p`
    font-size: 16px;
`

const Notif = styled.p`
    color: green;
    margin-top: 5px;
`

const Error = styled.p`
    color: red;
    margin-top: 5px;
`

const Reload = styled.button`
    background-color: blue;
    padding: 5px;
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 5px;
`

function CreateService() {
    const { metadata, fetching } = useSelector(state => state.metadata)
    const { requested, requestLinkError, requesting } = useSelector(state => state.emailVerification)
    const [btnDisable, setBtnDisable] = useState(false)
    const { user } = useAuth0()
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (requestLinkError) {
            setBtnDisable(false)
        }
    },[requestLinkError])

    const requestEmailVerificationLink = () => {
        dispatch(requestLink(metadata.user_id))
        setBtnDisable(true)
    }

    const refetch = () => {
        dispatch(addMetadata(user))
    }

  return (
    <>
        { !isMobile ? <FreeTrialAlert />: null }
        {   
            isMobile ? <DashboardNoteContainer>
                            <DashboardNote>Dashboard is not supported on Mobile View. Please open this page on your laptop/desktop</DashboardNote>
                        </DashboardNoteContainer>:
            fetching ? <Loading loading={fetching} />:
            !metadata?.email_verified ? (
                <Container>
                    <Text>Your email is not verified. You need to Verify your email first before you can create a Profile.</Text>
                    <CTA onClick={requestEmailVerificationLink} disabled={btnDisable} >
                        {requesting ? 'Loading..': 'Request verification link'}</CTA>
                    { 
                    requested ? <MiniContainer>
                                    <Notif>Link sent successfully. Please check your inbox</Notif>
                                    <Reload onClick={refetch}>Reload</Reload>
                                </MiniContainer> :
                    requestLinkError ? <Error>{requestLinkError}</Error> : null
                    }
                </Container>
            ) :
            metadata?.user_metadata ? (
                <div className='service-options profile-t__options'>
                    <div className='profile-t__option profile-t__header'>
                        <h1 style={{ fontSize: '24px', fontWeight: 700 }} >Which Service Profile Do you want to create?</h1> 
                    </div>
                    <div className="service-options__block ">
                        <div className="service-options__item" onClick={() => history.push('/sub/create', { profileType: 'Vehicle Maintenance'})}>
                            <Link data-test='car-service-profile' className='service-options__link' to={''} >    
                                    <div className='service-options__grp'>
                                        <p className='service-options__text'>Vehicle Maintenance</p>
                                        <BsArrowRightSquare />             
                                </div>   
                            </Link>
                        </div>
                    </div>
                    <div className="service-options__block">
                        <div className="service-options__item" onClick={() => history.push('sub/create', { profileType: 'Roadside Assist'})}>
                            <Link data-test='roadside-profile' className='service-options__link' to={''} >         
                                    <div className='service-options__grp'>
                                        <p className='service-options__text'>Roadside Assist</p>
                                        <BsArrowRightSquare />                
                                </div>   
                            </Link>
                        </div>
                    </div>
                </div>
            ) : <div style={{ padding: '25px'}}>
                    <h1 style={{ color: 'red'}}>Unauthorised</h1>
                    <p>Your service account does not have permissions to create a Service Provider Profile</p>
                    <p>Logout and Go to Service Provider Signup to create the appropriate service account</p>
            </div>
        }
    </>
  )
}

export default CreateService