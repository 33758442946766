import React, { useReducer, useEffect } from "react"
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'

import { auth } from "./firebase";
import customAxios from '../redux/axios/axios'

export const FirebaseContext = React.createContext()
const googleProvider = new GoogleAuthProvider();

const initialState = { isAuthenticated: false, user: null }

const reducer = (state, action) => {
    switch (action.type) {
    case 'authenticate':
        return { isAuthenticated: true, user: action.payload.user };
    case 'logout':
        return { isAuthenticated: false, user: null };
    default:
        return initialState;
    }
}



export const FirebaseContextProvider = ({ children }) => {
    const [firebaseAuth, dispatch] = useReducer(reducer, initialState);
    

    useEffect(()=>{
        auth.onAuthStateChanged(async(user) => {
            if (user) {
                user.accessToken = user.refreshToken
                if (!auth.user) {
                    if (user.displayName) {
                        dispatch({ type: 'authenticate', payload: { user }})
                    } else {
                        try {
                            const token = await user.getIdToken()
                            const { data: { email, fullName }} = await customAxios.post('/apple-login-credentials/get',
                            { uid: user.uid },
                            { headers: { 'Authorization': `Bearer ${token}` } });
                            dispatch({ type: 'authenticate', payload: { user: {...user, displayName: fullName }} })
                        } catch (error) {
                            alert('Missing Credentials. You wont be able to make a booking as your credentials are incomplete. If this problem persists please contact support')
                        }
                    }   
                }
            } else {
                dispatch({ type: 'logout' })
            }
        })
    },[])

    const signInWithGoogle = async () => {
        try {
            await signInWithPopup(auth, googleProvider)
            
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
        };
        
    const logout = () => {
        signOut(auth);
        };

    return (
        <FirebaseContext.Provider value={{ firebaseAuth, logout, signInWithGoogle  }}>
            {children}
        </FirebaseContext.Provider>
    )
}