import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components'
import { BsCreditCard2FrontFill, BsInfoCircle } from "react-icons/bs";
import { useAuth0 } from '@auth0/auth0-react';

import axios from '../../../redux/axios/axios'
import { Loading } from '../../loader/loader';
import { textValidator } from '../../../utils/validators';
import { fetchBanks } from '../redux/banks-validation-list.slice';
import { Button } from '../../button/Button';
import { createTransferRecipient } from '../redux/transfer-recipient.slice';
import { saveTransferRecipientCode } from '../redux/save-recipient-code.slice';
import { validateBankAccount } from '../redux/verify-bank-account.slice';
import { Uploading } from '../../loader/loader';
import { fetchBankingDetails } from '../redux/banking-details.slice';

const Container = styled.div`
  height: 85vh;
  overflow: scroll;
  padding-bottom: 10px;
`

const Error = styled.p`
  color: red;
  text-align: right;
`

const ActionError = styled.p`
  color: red;
`

const BankLabelContainer = styled.div`
display: flex;
    gap: 5px;
`

const FormContainer = styled.div`
  background: #f1f1f1;
    border-radius: 8px;
    padding: 50px;
`

const DetailItem = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 10px;
    @media (min-width: 450px) {
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }
`

const DetailsContainer = styled.div`
      background-color: #f1f1f1;
      padding: 20px;
      border-radius: 8px;
`

const Field = styled.p`
      font-weight: bold;
`

const ButtonsContainer = styled.div`
display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 20px;
`

const FormTextInput = ({ formErrors, handleOnChange, placeholder, label, name, autocomplete, value }) => {
  
    return (
      <div>
        <div className="booking__form-item">
              <label htmlFor={name}>{label}*:</label>
              <input type="text" 
                      name={name} 
                      id={name} 
                      value={value}
                      list={name}
                      placeholder={placeholder}
                      onChange={handleOnChange} 
                      className='booking__input'
              />
              {
                autocomplete ? <datalist id={name}>
                                    {
                                      [1,2,3,4,5].map(item => {
                                        return (
                                          <option style={{ width:'90%' }} key={Math.random()} value={item}>{item}</option>
                                        )
                                      })
                                    }
                              </datalist> : null
              }
            </div>
            {formErrors[name] ? <Error >{formErrors[name]}</Error> : null }
      </div>
      
    )
  }

const ListItemsWithAutoCompletion = ({data, value, setSelectedBank }) => {

    const onChange = (e) => {
        let { value } = e.target
        setSelectedBank(value)
    }

    return (
        <div>
            <div className='form__address-block'>
                    <div style={{width:'100%', textAlign: 'end' }}>
                    <input className='form__banks' list={`autocompletion`} type='text' autoComplete='on'
                            value={value} onChange={onChange} placeholder='type in your bank' />
                    <datalist id={`autocompletion`}>
                      {data.map(item => {
                        return (
                          <option style={{width:'100%'}} key={Math.random()} value={item.name}>{item.name}</option>
                        )
                      })}
                    </datalist>
                    </div>
                  </div>
        </div>
    )
}

function TransferRecipient() {
    const { banks: banksList, banksError } = useSelector(state => state.banksValidationList)
    const { bankingDetails, bankingDetailsError, loading} = useSelector(state => state.bankingDetails)
    const { transferRecipient, transferRecipientError, creating } = useSelector(state => state.createTransferRecipient)
    const { savedTransferRecipient, saveTransferRecipientError, saving } = useSelector(state => state.saveTransferRecipient)
    const { verified, verifyError, verifying } = useSelector(state => state.verifyBankAccount)
    const [formData, setFormData] = useState({ 
      name: '', accountNumber: '', currency: 'ZAR', documentNumber: '', countryCode: 'ZA',
      accountType: 'business', documentType: 'businessRegistrationNumber' })
    const [banks, setBanks] = useState([])
    const [selectedBank, setSelectedBank] = useState(null)
    const [bankCode, setBankCode] = useState('')
    const [formErrors, setFormErrors] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const { user, getAccessTokenSilently } = useAuth0();
    const accessTokenRef = useRef(null)
    const dispatch = useDispatch()

    console.log('verified', verified)
    // console.log('banks', banksList)

    const handleOnChange = (e) => {
        let { name, value } = e.target
        setFormData({...formData, [name]: value })
        if (name === 'name') {
          textValidator(value) ? setFormErrors({...formErrors,[name]:'Only letters and spaces are allowed'}) : delete formErrors[name]
        }
    }

    useEffect(() => {
      const refetch = async() => {
          try {
              const accessToken = await getAccessTokenSilently({
                  audience: process.env.REACT_APP_AUTH0_AUDIENCE
              })
              dispatch(fetchBankingDetails(user.email, accessToken))
          } catch (error) {
              alert('Error fetching your Banking Details. Try again later')
          }
      }

      if (savedTransferRecipient) {
        setIsEdit(false)
        refetch()
      }
    },[savedTransferRecipient])

    useEffect(() => {
      if (verified) {
        dispatch(createTransferRecipient({...formData, bankCode}, accessTokenRef.current))
      }
    },[verified])

    useEffect(() => {
      if (transferRecipient) {
        console.log('transfer recipient object', transferRecipient)
        dispatch(saveTransferRecipientCode({
          ...formData, 
          bankCode, 
          email: user.email,
          recipientCode: transferRecipient.data.recipient_code }, accessTokenRef.current))
      }
    },[transferRecipient])

    useEffect(() => {
      !bankingDetails && dispatch(fetchBanks())
    },[])

    useEffect(() => {
        let newData = banks.filter((item) => {
            return item.name === selectedBank
        })
        let code = newData[0]?.code
        code && setBankCode(code)
    },[selectedBank]) 

    useEffect(() => {
        const getBanks = async() => {
            try {
                const { data } = await axios.get('/paystack/banks')
                setBanks(data?.data)
            } catch (error) {
                console.log(error)
            }
        }

        getBanks()
    },[])


    const proceed = async() => {
      if (!formData.accountNumber || !formData.accountType || !formData.documentNumber ||
          !formData.documentType || !formData.name
      ) {
        return alert('Missing fields. Please fill all the fields.')
      }
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        })
        accessTokenRef.current = accessToken
        let arr = banksList.filter((item) => {
          return item.name === selectedBank
        })
        if (arr.length) {
          return dispatch(validateBankAccount({...formData, bankCode }, accessToken))
        }
        dispatch(createTransferRecipient({...formData, bankCode }, accessToken))
      } catch (error) {
        alert('Error retrieving token. Try again')
      }
    }

    const editDetails = () => {
      setFormData({...formData, name: bankingDetails.name, accountNumber: bankingDetails.accountNumber,
          documentNumber: bankingDetails.documentNumber, accountType: bankingDetails.accountType,
          documentType: bankingDetails.documentType
      })
      setIsEdit(true)
    }

    const maskCard = () => {
      const n = bankingDetails.accountNumber.length;
      const lastFour = bankingDetails.accountNumber.slice(-4);
      const remaining = bankingDetails.accountNumber.slice(0, n - 4);
      const masked = "*".repeat(remaining.length) + lastFour;

    return masked;
    }

  return (
    <Container>
      <div className="dboard">
                    <BsCreditCard2FrontFill size={40} color='#010A58' />
                    <p className='dboard__text'>Bank Account Details</p>
                </div>
      {
        !bankingDetails || isEdit  ? (
<div>
        
        <section className="booking__block">
          <h2>{isEdit ? 'Add new Banking Details': 'You do not have Banking Details. You need to add your Banking Details below to be able to receive payouts.'}</h2>
            <FormContainer>
            <FormTextInput name='name'
                          placeholder='0XXXXXXXXX'
                          label='Account Full Name'
                          value={formData.name}
                          handleOnChange={handleOnChange}
                          formErrors={formErrors} 
            />
            <div className='form__item'>
              <div className='form__item'>
                <BankLabelContainer>
                  <label htmlFor="address">Identity Document Type*:</label>
                </BankLabelContainer>
              </div>
              <div className="form__address" style={{ textAlign: 'end'}}>
                <select name='documentType' onChange={handleOnChange}>
                  <option value="businessRegistrationNumber">Business Registration Number</option>
                  <option value="identityNumber">Identity Number</option>
                  <option value="passportNumber">Passport Number</option>
                </select>
              </div>
            </div>
            <FormTextInput name='documentNumber'
                          label='Identity Document Number'
                          value={formData.documentNumber}
                          placeholder='xxxxxxx'
                          handleOnChange={handleOnChange}
                          formErrors={formErrors}
                        //   autocomplete={autocomplete} 
            />
            <div className='form__item'>
              <div className='form__item'>
                <BankLabelContainer>
                <label htmlFor="address">Bank*:</label>
                <div className="form__tooltip-icon" title='Select the Bank suggested from the autocomplete dropdown. Entering your own Bank will cause payments issues.'>
                        <BsInfoCircle size={20} color='blue' />
                    </div>
                </BankLabelContainer>
              </div>
              <div className="form__address" >
              <ListItemsWithAutoCompletion data={banks}           
                                    setSelectedBank={setSelectedBank}       
                  />
              </div>
            </div>
            <div className='form__item'>
              <div className='form__item'>
                <BankLabelContainer>
                  <label htmlFor="address">Account Type*:</label>
                </BankLabelContainer>
              </div>
              <div className="form__address" style={{ textAlign: 'end'}} >
                <select name='accountType' onChange={handleOnChange}>
                  <option value="business">Business</option>
                  <option value="personal">Personal</option>
                </select>
              </div>
            </div>
            <FormTextInput name='accountNumber'
                          label='Account Number'
                          value={formData.accountNumber}
                          placeholder='1000229222'
                          handleOnChange={handleOnChange}
                          formErrors={formErrors}
                          autocomplete={banks} 
            />
            <FormTextInput name='bankCode'
                          placeholder='xxx'
                          label='Bank Code'
                          value={bankCode}
                          handleOnChange={handleOnChange}
                          formErrors={formErrors} 
            />
            <FormTextInput name='currency'
                          placeholder='xxx'
                          label='Currency'
                          value={formData.currency}
                          formErrors={formErrors} 
            />
            <FormTextInput name='countryCode'
                          placeholder='xxx'
                          label='Country Code'
                          value={formData.countryCode}
                          formErrors={formErrors} 
            />
            <ButtonsContainer>
              <Button background='#009dfe' 
                      color='white'
                      borderRadius="10px"
                      border='none'
                      disabled={Object.keys(formErrors).length || verifying || saving || creating }
                      onClick={proceed}
                      >
                         Continue 
              </Button>
              { isEdit ? <Button background='black' 
                      color='white'
                      borderRadius="10px"
                      border='none'
                      onClick={() => setIsEdit(false)}
                      >
                         Cancel 
              </Button>: null }
              {
                verifying ? <Uploading text="validating Bank Account.." color={'black'} />:
                saving ? <Uploading text="processing.." color={'black'} />:
                creating ? <Uploading text="creating Transfer Reference.." color={'black'} />:
                transferRecipientError ? <ActionError>{transferRecipientError}</ActionError>:
                verifyError ? <ActionError>Validation Failed due Incorrect Details. Please double check your details and try again</ActionError>:
                transferRecipientError ? <ActionError>{transferRecipientError}</ActionError>: 
                saveTransferRecipientError ? <ActionError>{saveTransferRecipientError}</ActionError>:
                bankingDetailsError ? <ActionError>{bankingDetailsError}</ActionError>:
                banksError ? <ActionError>{banksError}</ActionError>: null
              }
            </ButtonsContainer>
            </FormContainer>
          </section>
    </div>
        ): (
          <DetailsContainer>
              <h4>Bank Account Details</h4>
              <p>NB: If you change your Subscription card you must also update these details to reflect the new Card otherwise your Weekly payouts will be sent to the old card</p>
              <DetailItem>
                      <Field>Account Name:</Field>
                      <p>{bankingDetails?.name}</p>
              </DetailItem>
              <DetailItem>
                      <Field>Account Number:</Field>
                      <p>{maskCard(bankingDetails?.accountNumber)}</p>
              </DetailItem>
              <div style={{ textAlign: 'end', marginTop: 30 }}>
              <Button background='#009dfe' 
                      color='white'
                      borderRadius="10px"
                      border='none'
                      onClick={editDetails}
                      >
                         Change Card 
              </Button>
              </div>
          </DetailsContainer>
        )
      }
    </Container>
    
  )
}

export default TransferRecipient