import React, { useState, useEffect } from 'react'
import { CgChevronDoubleRightR, CgChevronDoubleLeftR } from 'react-icons/cg'
import { IoMdQuote } from 'react-icons/io'
import styled from 'styled-components'
import { useHistory, Link } from 'react-router-dom'

const Image = styled.img`
  width: 80%;
    height: 80%;
    object-fit: contain;
    max-width: 318px;
    max-width: 318px;
`

const Button = styled(Link)`
  position: absolute;
  padding: 15px;
  bottom: 10%;
  left: 35%;
  background: rgb(0, 157, 254);
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
`

export const MiniNav = React.memo(({links,heading}) => {
  const [active, setActive] = useState(0)
  
  const handleSetActive = (idx) => {
    setActive(idx)
  }

  return (
    <div className="submenu">
        <h1 className='submenu__h'>{heading}</h1>
        <div className="submenu__links">
        {links.map((link,idx) => { 
          const {heading}  = link
          return (
               <p key={idx} onClick={()=>handleSetActive(idx)} className={`submenu__p ${active === idx ? 'submenu__active' : ''}`}>{heading}</p>
          )
        })}
        </div>
        <div className="submenu__images">
        {links.map(({img,text},idx) => {       
          return (
            <div key={idx} className={`submenu__image ${active === idx ? 'submenu__active-img' : ''}`}>
              <img className="submenu__img" src={img} alt="submenu-icon" loading="lazy" />
            </div>
            )
        })}
        </div>
    </div>
  )
})

export const SlideShow = ({data,block}) => {
  const [index, setIndex] = useState(0)
  const history = useHistory()

  useEffect(()=>{
    const lastIndex = data.length - 1
    if (index < 0) {
      setIndex(lastIndex)
    }
    if (index > lastIndex) {
      setIndex(0)
    }
  },[data,index])

  useEffect(()=>{
    let slideshow = setInterval(()=>{
      setIndex(prev => prev + 1)
    },3000)
    return () => clearInterval(slideshow)
  },[index])

  return (
    <article className="fwicon slideshow">
      {data.map((opt,idx) => {
        const {img, link } = opt
        const size = 'fa fa-2x' || block
        let position = 'slideshow__next'
        if (idx === index) {
          position = 'slideshow__active'
        } else if (idx === index - 1 || (index === 0 && idx === data.length - 1)) {
          position = 'slideshow__last'
        }
        return (
            <div onClick={() => link && history.push(link)} key={idx} className={`fwicon__feature slideshow__feature ${position} `}>
              <Image src={img} alt="feature-icon" loading="lazy" width='318' height='318' />
              { index === 0 ? <Button to='/services'>View Services</Button>: 
                      index === 1 ? <Button to='/services'>Create Booking</Button>: 
                      index === 2 ? <Button to='/signup'>Sign Up Now</Button>: null }
          </div>
        )
      })}
      <div className='slideshow__btns'>
        <i onClick={()=>{
          setIndex(prev => prev-1)
        }} className='slideshow__back slideshow-remove'><CgChevronDoubleLeftR size={30}/></i>
        <i onClick={()=>{
          setIndex(prev => prev+1)
        }} className='slideshow__forward slideshow-remove'><CgChevronDoubleRightR size={30}/></i>
      </div>
    </article>
  )
}

const Slider = ({data,block}) => {
  const [index, setIndex] = useState(0)

  useEffect(()=>{
    const lastIndex = data.length - 1
    if (index < 0) {
      setIndex(lastIndex)
    }
    if (index > lastIndex) {
      setIndex(0)
    }
  },[data,index])

  useEffect(()=>{
    let slideshow = setInterval(()=>{
      setIndex(prev => prev + 1)
    },3000)
    return () => clearInterval(slideshow)
  },[index])

  return (
    <article className="fwicon imgslider">
      {data.map((opt,idx) => {
        const {text,icon} = opt
        const size = 'fa fa-2x' || block
        let position = 'imgslider__next'
        if (idx === index) {
          position = 'imgslider__active'
        } else if (idx === index - 1 || (index === 0 && idx === data.length - 1)) {
          position = 'imgslider__last'
        }
        return (
          <div key={idx} className={`fwicon__feature imgslider__feature ${position} `}>
              <div className='fwicon__i'>{icon}</div>
              <p className='fwicon__p'>{text}</p>
              <p className='imgslider__icon imgslider-remove'><IoMdQuote /></p>
          </div>
        )
      })}
      <div className='imgslider__btns'>
        <i onClick={()=>{
          setIndex(prev => prev-1)
        }} className='imgslider__back imgslider-remove'><CgChevronDoubleLeftR/></i>
        <i onClick={()=>{
          setIndex(prev => prev+1)
        }} className='imgslider__forward imgslider-remove'><CgChevronDoubleRightR/></i>
      </div>
    </article>
  )
}

export const StaticSlider = ({data,block}) => {
  const [index, setIndex] = useState(0)

  useEffect(()=>{
    const lastIndex = data.length - 1
    if (index < 0) {
      setIndex(lastIndex)
    }
    if (index > lastIndex) {
      setIndex(0)
    }
  },[data,index])

  return (
    <article className="imgslider imgslider__static">
      {data.map((link,idx) => {
        let position = 'imgslider__next'
        if (idx === index) {
          position = 'imgslider__active'
        } else if (idx === index - 1 || (index === 0 && idx === data.length - 1)) {
          position = 'imgslider__last'
        }
        return (
          <div key={idx} className={`imgslider__feature static-imgslider__img-container ${position} `}>
              <img className='static-imgslider__img' src={`${process.env.REACT_APP_S3_BUCKET_URL}/${link}`} alt="img" />
          </div>
        )
      })}
      { data.length > 1 ? <div className='imgslider__btns'>
        <CgChevronDoubleLeftR size={15} className='imgslider__back imgslider-remove'
                                    onClick={()=>{
                                      setIndex(prev => prev-1)
                                    }}
        />
        <CgChevronDoubleRightR size={15} onClick={()=>{
                                setIndex(prev => prev+1)
                                }}
                                className='imgslider__forward imgslider-remove'
        />
      </div>: null }
    </article>
  )
}

export default Slider
