import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../../redux/axios/axios'

const initialState = {
  verifying : false,
  verified: false,
  verifyError: ''
}

const verifyBankAccount = createSlice({
  name: 'verifyBankAccount',
  initialState,
  reducers: {
    verifyBankAccountRequest(state) {
      state.verifying = true
    },
    verifyBankAccountSuccess(state, action) {
        state.verifying = false
        state.verified = action.payload
    },
    verifyBankAccountError(state, action) {
      state.verifying = false
      state.verifyError = action.payload
    },
  }
})

export const { verifyBankAccountRequest, verifyBankAccountSuccess, verifyBankAccountError } = verifyBankAccount.actions

export const validateBankAccount = (postData, token) => async (dispatch) => {
  dispatch(verifyBankAccountRequest())
  try {
      const { data: response } = await customAxios.post('/paystack/verify-bank-account',{ ...postData },{
                                                                             headers: {
                                                                                 Authorization: `Bearer ${token}`
                                                                              }
                                                                          })
      let { data } = response                                                                    
      dispatch(verifyBankAccountSuccess(data.verified))
      console.log('verify data',response)
  } catch(err)  {
      console.log(err);
      dispatch(verifyBankAccountError(err.message))
  }      
}



export default verifyBankAccount.reducer
