import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components'
import { TbCircleDotFilled } from "react-icons/tb";
import { FaPlus } from "react-icons/fa";
import { useAuth0 } from '@auth0/auth0-react';
import PaystackPop from '@paystack/inline-js'

import { Button, FormButton } from '../../../Components/button/Button';
import { InteractiveModal } from '../../../Components/modal/Modal';
import { fetchServicesSubscription } from '../../../Components/subscriptions/services.slice';
import SubscriptionSkeleton from '../../../Components/skeletons/subscription';
import customAxios from '../../../redux/axios/axios'
import Subscription from './Subscription';
import { profileRefetch } from '../redux/profile.detail.slice';
import { Card, Heading, Option, OptionsContainer, Price, PriceContainer, Interval, ButtonContainer } from '../../../views/services/create-service/CreateSubscription';

const Container = styled.div`
    padding: 50px;
`

const SubContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin: 50px auto;
`


const popup = new PaystackPop()

function CreateNewSubscription({ profileType, setActive, oldSubscription }) {
    const {loading, subscription, subscriptionError} = useSelector(state => state.servicesSubscription)
    const [testPayment, setTestPayment] = useState({ loading: false, success: false, error: '' })
    const [subscriptionPayment, setSubscriptionPayment] = useState({ creating: false, success: false, error: '' })
    const [isBtnDisabled, setIsBtnDisabled] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { getAccessTokenSilently, user:{ email } } = useAuth0();
    const dispatch = useDispatch()
    
    const { type, companyID } = profileType

    const options = {
        services: {
            premium: ['Listings Page', `Up to ${subscription?.plans?.premium?.max} Concurrent Bookings at any time`],
            basic: ['Listings Page', `Up to ${subscription?.plans?.basic?.max} Concurrent Bookings at any time` ],
            standard: ['Listings Page', `Up to ${subscription?.plans?.standard?.max} Concurrent Bookings at any time`]
        },
        towing: {
            premium: ['Listings Page', `Up to ${subscription?.plans?.premium?.max} Registered Towers`],
            basic: ['Listings Page', `Up to ${subscription?.plans?.basic?.max} Registered Towers` ],
            standard: ['Listings Page', `Up to ${subscription?.plans?.standard?.max} Registered Towers`]
        }
    }

    const subOptions = [
        { 
            plan: 'Basic',
            amount: subscription?.plans?.basic?.amount,
            planCode: subscription?.plans?.basic?.planCode,
            options: type === 'Vehicle Maintenance' ? options.services.basic: options.towing.basic,
        },
        { 
            plan: 'Standard',
            amount: subscription?.plans?.standard?.amount,
            planCode: subscription?.plans?.standard?.planCode,
            options: type === 'Vehicle Maintenance' ? options.services.standard: options.towing.standard,
        },
        {
            plan: 'Premium',
            amount: subscription?.plans?.premium?.amount,
            planCode: subscription?.plans?.premium?.planCode,
            options: type === 'Vehicle Maintenance' ? options.services.premium: options.towing.premium,
        }
    ]

    const openModal = (plan) => {
        setSelectedPlan(plan)
        setIsModalOpen(true)
    }

    const createTestPayment = async() => {
        setIsModalOpen(false)
        setIsBtnDisabled(true)
        popup.newTransaction({
            key: 'pk_live_6ba020349f69929e6f843966dd483f1edbe7a487',
            email,
            amount: 100,
            metadata: {
                profileType: type
            },
            onSuccess: (transaction) => {
                createSubscription()
            },
            onCancel: () => {
                setIsBtnDisabled(false)
                setSelectedPlan('')
            },
            onError: () => {
                setSelectedPlan('')
                setTestPayment({...testPayment, error: true })
            }
        })
    }

    const createSubscription = async() => {
        try {
            setSubscriptionPayment({...subscriptionPayment, creating: true })
            const { planCode, plan } = selectedPlan
            const accessToken = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE
            })
            let currentDate = new Date();
            currentDate.setMinutes(currentDate.getMinutes() + 1);
            let start_date = currentDate.toISOString()
            let postData = { 
                plan: planCode, 
                email, 
                profileType: type,
                companyID,
                subscriptionTier: plan,
                isSubscriptionUpdate: true,
                start_date,
                currentSubscriptionTier: ''
            }
            if (oldSubscription) {
                const { subscription_code, email_token } = oldSubscription
                await customAxios.post('/subscriptions/cancel',{ subscription_code, email_token },{
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                     }
                 })
            }
            await customAxios.post('/subscriptions/create', { ...postData },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                     }
                 }
            )
            setSubscriptionPayment({...subscriptionPayment, success: true, creating: false })
        } catch (error) {
            setIsBtnDisabled(false)
            setSubscriptionPayment({...subscriptionPayment, error: true, creating: false })
        }
    }

    useEffect(() => {
        dispatch(fetchServicesSubscription(type))
    },[])

    useEffect(() => {
        if (testPayment.error || subscriptionPayment.error) {
            setIsBtnDisabled(false)
        }
    },[testPayment, subscriptionPayment])

    useEffect(() => {
        const fetch = async() => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE
                })
                dispatch(profileRefetch(email,companyID,type,accessToken))  
            } catch (error) {
                console.log(error);
            }
        }

        if (subscriptionPayment.success) {
            fetch()
            setActive({ screen: 'Subscription', view: <Subscription profileType={profileType} setActive={setActive}  /> })
        }
    },[subscriptionPayment])

    return (
        <Container>
            {
                loading ? <SubscriptionSkeleton />:
                subscriptionError ? <p className='error'>Error Fetching Data. Try again</p>:
            (
            <div>
            <h1>Choose your Subcription Plan</h1>
            <p>Subscription plans can be changed later</p>
            <SubContainer>
            {
                subOptions.map(({ plan, options, amount, planCode }, idx) => {
                    return (
                        <Card key={idx}>
                            <Heading>
                                <TbCircleDotFilled color={plan === 'Basic' ? 'blue':  plan === 'Standard' ? 'green': 'purple' } />
                                <p style={{ color: plan === 'Basic' ? 'blue': plan === 'Standard' ? 'green': 'purple', fontWeight: 'bold' }}>{plan}</p>
                            </Heading>
                            <PriceContainer>
                                <Price>R{amount}</Price>
                                <Interval>/ month </Interval>
                            </PriceContainer>
                            <OptionsContainer>
                                {options.map((el,idx) => <Option key={idx}>
                                                            <FaPlus size={10} />
                                                            <p>{el}</p>
                                                        </Option>)}         
                            </OptionsContainer>
                            <ButtonContainer>
                            <Button
                                background='green' 
                                color='white' 
                                border='none'
                                padding='15px'
                                borderRadius='5px'
                                width='200px'
                                disabled={isBtnDisabled}
                                onClick={() => openModal({ plan, amount, planCode })}>
                                    Subscribe
                            </Button>
                            </ButtonContainer>
                        </Card>
                    )
                })
            }
            </SubContainer>
            {   subscriptionPayment.creating ? <p style={{ textAlign: 'center' }}>Creating Subscription..</p> :
                subscriptionPayment.success ? <p style={{ textAlign: 'center', color: 'green' }}>Subscription Created Successfully.</p> :
                testPayment.error ? <p className='error'>Test Payment Error. Try again</p> : 
                subscriptionPayment.error ? <p className='error'>Subscription Payment Error. Try again</p> : null }
            {
            isModalOpen ? (
                <InteractiveModal height='90vh'> 
                    <h3>You have Selected A {selectedPlan.plan} Plan</h3>
                    <p style={{ lineHeight: 1.5, marginBottom: 10 }}>We will create a Test Payment of ZAR 1.00 to test if your Bank Card is valid. If it succeeds then we will proceed with the Subscription creation process.</p>
                    <div className="imodal__btns">
                        <FormButton background='#5282BD' 
                                    color='white' 
                                    onClick={createTestPayment}>
                                    Continue
                        </FormButton>
                        <FormButton borderRadius='5px' 
                                    onClick={() => setIsModalOpen(false)}>
                                        Cancel
                        </FormButton>
                    </div>
                    
                </InteractiveModal>
                ) : null 
            }
        </div>
        )}
        </Container>
    )
}

export default CreateNewSubscription