import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { AiOutlineFilePdf } from 'react-icons/ai'
import { MdClose, MdVerified, MdOutlineCloudUpload  } from 'react-icons/md';
import { BsFillCheckSquareFill } from 'react-icons/bs'
import { VscUnverified, VscVerified, VscThreeBars } from 'react-icons/vsc'

import { Uploading } from '../../loader/loader'
import { FormButton } from '../../button/Button'
import { CertificatesDropZone } from '../../fileUploads/DropZones'
import AllFileUploads from '../../fileUploads/UploadProgressBar'
import { verificationRequestReset, createProfileVerification } from '../redux/verification.create.slice';
import { fetchPreUploadData, fetchPreUploadDataReset } from '../../fileUploads/preupload.slice';
import { uploadFiles, filesUploadReset } from '../../fileUploads/upload.slice';
import { updateFiles, resetProgressBar } from '../../fileUploads/progress.slice';
import { profileRefetch } from '../redux/profile.detail.slice';

const ButtonContainer = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
  paddingRight: '35px',
  paddingTop: '50px',
  textAlign: 'right',
  marginBottom: '50px'
}

const UploadSuccess = () => {
  return (
    <div>
      <div className="tower__success">
          <BsFillCheckSquareFill color='green' />
          <p >Verification request submitted successfully. </p>
      </div>
      <p style={{ textAlign: 'center'}}>Your Profile verification has started. Check your email for a confirmation.</p>
      </div>
  )
}

const verificationContainer = {
  display: 'flex',
  gap: '5px',
  alignItems: 'center'
}

const verificationStatus = {
  fontWeight: 700,
  fontSize: '20px'
}

const verificationText = {
  fontSize: '20px'
}

const fileUploads = {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  marginBottom: '10px'
}

const fileUploadsText = {
  fontSize: '22px'
}

const VerificationStatus = ({status, exp}) => {

  return (
    <div style={verificationContainer}>
      <p style={verificationText}>Verification status: </p>
      { status === 'false' || status ===  'expired' ? <div style={verificationContainer}>
                                <VscUnverified size={30} color='red' />
                                <p style={verificationStatus}>Unverified</p>
                              </div> :
        status === 'verified'  ?  <div style={verificationContainer}>
                                <VscVerified size={30} color='green' />
                                <p style={verificationStatus}>Verified</p>
                              </div> :   
         status === 'pending'  ?  <div style={verificationContainer}>
                              <VscThreeBars size={30} color='orange' />
                              <p style={verificationStatus}>Pending</p>
                            </div> :   null             
                              }
    </div>
    
  )
}

function DashboardProfileVerification({ profileType }) {
  const { profile: { verified, companyName, exp } } = useSelector(state => state.dashboardProfile)
    const {data,fetchError,fetched,fetching} = useSelector(state => state.preUpload)
    const { uploaded, uploadFilesError, uploading } = useSelector(state => state.uploadFiles)
    const { creating, created, createProfileVerificationError } = useSelector(state => state.profileVerification)
    const { getAccessTokenSilently, user: { email,sub }} = useAuth0();
    const [btnDisable, setBtnDisable] = useState(false)
    const [files, setFiles] = useState([])
    const dispatch = useDispatch()
    const accessToken = useRef(null)

    const { type, companyID } = profileType

    useEffect(() => {
      return () => {
        dispatch(resetProgressBar())
        dispatch(filesUploadReset())
        dispatch(fetchPreUploadDataReset())
      }
    },[])

    useEffect(() => {
        const filesProgressStates = files.map(({name}) => ({ name, percentage: 0}))
        dispatch(updateFiles({ Items: filesProgressStates }))
    },[files])

    useEffect(()=>{
        ( uploadFilesError || fetchError) && setBtnDisable(false)
      },[uploadFilesError, fetchError])

    useEffect(() => {
      if (uploaded) {
        const filesS3links = []
        for(let file of files){
          filesS3links.push(`${data.key}/${file.name}`)
        }
        
        const verificationRequestData = {
          companyName,
          email,
          files: filesS3links,
          state: 'pending'  
        }

        if (type === 'Roadside Assist') {
          verificationRequestData['companyID'] = companyID
          verificationRequestData['type'] = 'roadside_assist'
        } else {
          verificationRequestData['service'] = 'vehicle_maintenance'
          verificationRequestData['type'] = 'vehicle_maintenance'
        }
        
        const postData = {
            type,
            email,
            data: verificationRequestData
        }
        dispatch(createProfileVerification(postData,sub,accessToken.current))  
      }
    },[uploaded])

    useEffect(()=> {
    if (fetched) {
        const form = new FormData();
        for (let key in data) {
        if (key !== 'upload_url' && key !== 'key') form.append(key, data[key])
        }
        dispatch(uploadFiles(form,files,data.upload_url,data.key))      
    } 
    },[fetched])

    const handleFilesChange = (files) => setFiles(files) 

    const handleSubmit = async() => {
        if (!files.length) {
            return alert('No files selected. Please upload files.')
        } else {
            try {
                const token = await getAccessTokenSilently({
                  audience: process.env.REACT_APP_AUTH0_AUDIENCE
                })
                accessToken.current = token
                dispatch(fetchPreUploadData(token, email))
              } catch (error) {
                console.log(error);
              }
        }
    }

    const refetch = async() => {
      dispatch(resetProgressBar())
      dispatch(filesUploadReset())
      dispatch(fetchPreUploadDataReset())
      dispatch(verificationRequestReset())
      dispatch(profileRefetch(email,companyID,type,accessToken.current))
  }

    const fileDeleteUpdate = (name) => {
      const newFiles = files.filter(img => img.name !== name)
      handleFilesChange(newFiles)
    }

  return (
    <>
        <div className="dboard">
            <MdVerified size={40} color='#010A58' />
            <p className='dboard__text'>Verification</p>
        </div>
        <article>
          <VerificationStatus status={verified} exp={exp} />
          {verified === 'verified' ? <div style={{ marginTop: 10 }}>
              <p style={{ fontSize: 'medium' }}>Verified Until: {new Date(exp).toLocaleString()}</p>
              <p style={{ color: 'blue', marginTop: 10 }}>* To avoid disruption to your Services Upload Documents a few days before the Expiration Date.</p>
          </div>: verified==='false' && type ==='Roadside Assist' ? <p style={{ color: 'blue', marginTop: 10 }}>*You should add your Towers (under the Towers section) before uploading the required Documents </p>: null}
        </article>
        <article className='uploads'>
            <div style={fileUploads}>
              <MdOutlineCloudUpload size={40} />
              <p style={fileUploadsText}>File Uploads</p>
            </div>
            <p className='form__h'>Certificates and All Supporting Documents (.pdf)</p>
            <div style={{ paddingLeft: '50px'}}>
              <li>Company Registration Document</li>
              <li>Director ID</li>
              <li>Proof of Company Address</li>
              <li>Proof of Banking Details</li>
              { type ==='Vehicle Maintenance' ? <>
                  <li>Prof of Accreditation with Relevant Automotive Body (e.g RMI, MIWA)</li>
                  <li>Certified Mechanic/Technician Licences</li>
                  <li>Proof of Public Liability Insurance</li>
                  <li>Certified Mechanic/Technician Licences</li>
                  <li>Vehicle Service License (if applicable)</li>
                  <li>Health and Safety Compliance Certificate</li>
                </>: type ==='Roadside Assist' ? <>
                  <li>Proof of registration with SATRA (or any relevant Towing Body)</li>
                  <li>All Driver Licences</li>
                  <li>Proof of 3rd party Liability Cover</li>
                  <li>Vehicle Registration Papers</li>
                  <li>Vehicle Roadworthiness Certificates</li>
                  <li>Proof of Comprehensive Insurance</li>
                  <li>Letter of Good Standing with Compensation Fund</li>
                </>: null }
            </div> 
            <CertificatesDropZone handleFilesChange={handleFilesChange} />
            <div className='uploads__imgsblock'> 
              {Array.from(files).map((img,idx) => {
                const {name} = img
                return (
                  <div key={idx} className='uploads__images'>
                    <div className="uploads__container uploads__file-background">
                      <AiOutlineFilePdf className='uploads__img' size={50} />
                      <MdClose color='red' size={30} className='uploads__close' cursor='pointer' onClick={() =>fileDeleteUpdate(name)}/>
                    </div>
                    <h6 style={{ width: '200px'}}>{name}</h6>
                  </div>
                )
              })}
            </div> 
            { files.length ? <AllFileUploads /> : null }
            { fetching ? <Uploading text="fetching metadata" color={'black'}/> : 
              uploading ? <Uploading text="uploading files" color={'black'} /> : 
              creating ? <Uploading text="submitting request" color={'black'}/> : null }
            { uploadFilesError ? <p className='error'>upload files error: {uploadFilesError}</p> : 
              fetchError ? <p className='error'>fetch preupload data error: {fetchError}</p> :
              createProfileVerificationError ? <p className='error'>create profile error: {createProfileVerificationError.message}</p> : null }
              { created ?  <UploadSuccess /> : null }
            <div style={ButtonContainer}>
                { !created ? <FormButton disabled={btnDisable} 
                                background='green' 
                                color='white' 
                                onClick={handleSubmit}>
                                Upload Files
                            </FormButton> :
                            <FormButton background='#5282BD' 
                                        color='white' 
                                        onClick={refetch}>
                                        Done
                            </FormButton> }
            </div> 
      </article>
    </>
      
  )
}

export default DashboardProfileVerification