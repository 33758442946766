import Skeleton from 'react-loading-skeleton'

const RoadsideAssistSkeleton = () => {

    return (
    <div className="dashboard">
        <div className='dashboard__sidebar'>
            <div className="dashboard__items">
                <div className="dashboard__item">
                    <div className='dashboard__option-header'><p><span style={{ fontWeight: 300, fontSize: 'small' }}>Hello,</span><Skeleton className='dashboard__option' /></p></div>
                </div> 
                <div className="dashboard__item">
                    <Skeleton className='dashboard__option'  />
                </div>
                <div className="dashboard__item">
                            <Skeleton className='dashboard__option' />
                </div>  
                <div className="dashboard__item">
                        <p className='dashboard__option-header'>Account</p>
                        <Skeleton className='dashboard__option' />
                        <Skeleton className='dashboard__option' />
                        <Skeleton className='dashboard__option' />
                </div>  
            </div>
            <div className="dashboard__content"></div>
        </div>
        <div className="dashboard__body">
            <div className='dashboard__skeleton-active' >
                <Skeleton height={600} containerClassName='dashboard__skeleton-active' />
            </div>
        </div>
    </div>
    )
}

export default RoadsideAssistSkeleton