import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { MdOutlineSubscriptions, MdCallToAction } from 'react-icons/md'
import { TbCircleDotFilled } from "react-icons/tb";
import { FaPlus } from "react-icons/fa";
import useCollapse from 'react-collapsed'
import styled from 'styled-components';
import { AiOutlineReload } from 'react-icons/ai'

import { fetchSubscription } from '../redux/subscription.detail.slice';
import { fetchSubscriptionUpdateLink, fetchedSubscriptionUpdateLinkReset } from '../redux/subscription.update.slice';
import { AttributeContainer, AttributeField } from './ManageAccount';
import { Button, FormButton } from '../../button/Button';
import { fetchServicesSubscription } from '../../subscriptions/services.slice';
import { InteractiveModal } from '../../modal/Modal';
import { Loading, Uploading } from '../../loader/loader';
import CreateNewSubscription from './CreateSubscription';
import customAxios from '../../../redux/axios/axios'
import { profileRefetch } from '../redux/profile.detail.slice';
import { Card, Heading, Price, PriceContainer, Interval, OptionsContainer, ButtonContainer, Option } from '../../../views/services/create-service/CreateSubscription';

export const Actions = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

const Description = styled.p`
    margin-top: 10px;
    color: ${props => props.color};

`

const CaptionsContainer = styled.div`
    margin-top: 10px;
`

const Caption = styled.p`
    font-size: x-small;

`

function Subscription({ profileType, setActive }) {
    const { profile } = useSelector(state => state.dashboardProfile)
    const { loading, subscription, subscriptionError } = useSelector(state => state.subscription)
    const { renewing, renewSuccess, renewError } = useSelector(state => state.renewSubscription)
    const {loading: sLoading, subscription: subs, subscriptionError: plansError } = useSelector(state => state.servicesSubscription)
    const { fetching, link, linkError } = useSelector(state => state.subscriptionUpdate)
    const [changeSubscription, setChangeSubscription] = useState({ changing: false, changed: false, error: '' })
    const [isUpdateExpanded, setIsUpdateExpanded] = useState(false)
    const [isBtnDisabled, setIsBtnDisabled] = useState(false)
    const [subscriptionOptions, setSubscriptionOptions] = useState([])
    const [isModalOpen, setIsModalOpen] = useState({ value: false, type: '' })
    const [checked, setChecked] = useState('')
    const [tiers, setTiers] = useState(null)
    const [selectedTier, setSelectedTier] = useState(null)
    const { user, getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch()
    const token = useRef()

    const { currentSubscriptionTier, trialExpirationTimeStamp, subscriptionTier, subscriptionStatusFilter } = profile
    const { type, companyID } = profileType
    
    const { getCollapseProps: getUpdateCollapseProps, getToggleProps: getUpdateToggleProps } = useCollapse(isUpdateExpanded)

    const { amount, description, interval, status, next_payment_date, name, subscription_code, email_token } = subscription
    
    // const options = {
    //     services: {
    //         premium: ['Listings Page', `Up to ${subs?.plans?.premium?.max} Concurrent Bookings at any time`],
    //         basic: ['Listings Page', `Up to ${subs?.plans?.basic?.max} Concurrent Bookings at any time` ],
    //         standard: ['Listings Page', `Up to ${subs?.plans?.standard?.max} Concurrent Bookings at any time`]
    //     },
    //     towing: {
    //         premium: ['Listings Page', `Up to ${subs?.plans?.premium?.max} Registered Towers`],
    //         basic: ['Listings Page', `Up to ${subs?.plans?.basic?.max} Registered Towers` ],
    //         standard: ['Listings Page', `Up to ${subs?.plans?.standard?.max} Registered Towers`]
    //     }
    // }

    const options = {
        services: {
            platinum: ['Listings Page', `${subs?.plans?.platinum?.commission}% Booking Commission`],
            silver: ['Listings Page', `${subs?.plans?.silver?.commission}% Booking Commission` ],
            gold: ['Listings Page', `${subs?.plans?.gold?.commission}% Booking Commission`],
        },
        towing: {
            platinum: ['Listings Page', `${subs?.plans?.platinum?.commission}% Towing Commission`],
            silver: ['Listings Page', `${subs?.plans?.silver?.commission}% Towing Commission` ],
            gold: ['Listings Page', `${subs?.plans?.gold?.commission}% Towing Commission`],
        }
    }

    // const subOptions = [
    //     { 
    //         plan: 'Basic',
    //         amount: subs?.plans?.basic?.amount,
    //         planCode: subs?.plans?.basic?.planCode,
    //         options: type === 'Vehicle Maintenance' ? options.services.basic: options.towing.basic,
    //     },
    //     {
    //         plan: 'Premium',
    //         amount: subs?.plans?.premium?.amount,
    //         planCode: subs?.plans?.premium?.planCode,
    //         options: type === 'Vehicle Maintenance' ? options.services.premium: options.towing.premium,
    //     },
    //     {
    //         plan: 'Standard',
    //         amount: subs?.plans?.standard?.amount,
    //         planCode: subs?.plans?.standard?.planCode,
    //         options: type === 'Vehicle Maintenance' ? options.services.standard: options.towing.standard,
    //     }
    // ]

    const subOptions = [
        { 
            plan: 'Silver',
            amount: subs?.plans?.silver?.amount,
            planCode: subs?.plans?.silver?.planCode,
            options: type === 'Vehicle Maintenance' ? options.services.silver: options.towing.silver,
        },
        { 
            plan: 'Gold',
            amount: subs?.plans?.gold?.amount,
            planCode: subs?.plans?.gold?.planCode,
            options: type === 'Vehicle Maintenance' ? options.services.gold: options.towing.gold,
        },
        {
            plan: 'Platinum',
            amount: subs?.plans?.platinum?.amount,
            planCode: subs?.plans?.platinum?.planCode,
            options: type === 'Vehicle Maintenance' ? options.services.platinum: options.towing.platinum,
        }
    ]
    
    const changeSubscriptionOptions = [
        {
            option: 'Next Payment Date',
            caption: 'New Subscription payment will be charged on Current Subscription Next Payment Date.'
        },
        {
            option: 'Now',
            caption: "New Subscription payment will be charged Now. You will be charged the Full, New Subscription amount without Refunds"
        }
    ]
    
    useEffect(() => {
        const fetch = async() => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE
                })
                token.current = accessToken
                dispatch(fetchSubscription({ profileType: type, email: user.email }, accessToken))   
            } catch (error) {
                console.log(error);
            }
        }

        dispatch(fetchServicesSubscription(type))
        if (subscriptionTier !== 'Free') {
            fetch()
        }

        return () => {
            // setTiers(null)
            dispatch(fetchedSubscriptionUpdateLinkReset())
        }
    },[])

    useEffect(() => {
        if (subs && name) {
            const tiers = subOptions.filter(({ plan }) => {
                return !(name?.includes(plan))
            })
             
            setTiers(tiers)
            let tier = name?.split(' ')[1].toLowerCase()
            let type = name?.split(' ')[0].toLowerCase()
            setSubscriptionOptions(options[type][tier])
            
        }
    },[subs, name])

    useEffect(() =>{
        if (changeSubscription.changed) {
            setTimeout(async() => {
                const accessToken = await getAccessTokenSilently({
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE
                })
                dispatch(profileRefetch(user?.email, companyID, type, accessToken))
            },1000)
        }
    },[changeSubscription])

    const updateCard = () => {
        setIsModalOpen({ value: false, type: '' })
        dispatch(fetchSubscriptionUpdateLink(subscription_code, token.current ))
    }

    const changePlan = async() => {
        if (!subs) {
            return;
        }
        setIsBtnDisabled(true)
        setIsUpdateExpanded(false)
        setIsModalOpen({ value: false, type: '' })
        setChangeSubscription({...changeSubscription, changing: true })
        let start_date, currentSubscriptionTier
        if (checked.option === 'Next Payment Date') {
            let date = new Date(next_payment_date)
            date.setMinutes(date.getMinutes() + 5)
            start_date = date.toISOString()
            currentSubscriptionTier = name?.split(' ')[1]
        } else {
            // charge within the next minute
            let currentDate = new Date();
            currentDate.setMinutes(currentDate.getMinutes() + 1);
            start_date = currentDate.toISOString()
            currentSubscriptionTier = ''
        }
        let postData = { 
            plan: selectedTier?.planCode, 
            email: user?.email, 
            profileType: type,
            companyID,
            subscriptionTier: selectedTier?.plan,
            isSubscriptionUpdate: true,
            start_date,
            currentSubscriptionTier
        }
        
        try {
            const accessToken = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE
            })
            await customAxios.post('/subscriptions/cancel',{ subscription_code, email_token },{
                headers: {
                    Authorization: `Bearer ${accessToken}`
                 }
             })
            await customAxios.post('/subscriptions/create', {...postData},
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                     }
                 }
            )
        setChangeSubscription({...changeSubscription, changing: false, changed: true })
        } catch (error) {
            console.log('change err', error);
            setIsBtnDisabled(false)
            setChangeSubscription({...changeSubscription, changing: false, error: 'Error' })
        }
    }

    const createSubscription = async(oldSubscription) => {
        setIsUpdateExpanded(false)
        setActive({ link:'', view: <CreateNewSubscription setActive={setActive}  
                                                          profileType={profileType} 
                                                          oldSubscription={oldSubscription}
                />})
    }

    const fetch = async() => {
        if (subscriptionTier === 'Free') {
            return;
        }
        try {
            const accessToken = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE
            })
            token.current = accessToken
            dispatch(fetchSubscription({ profileType: type, email: user.email }, accessToken)) 
            dispatch(fetchedSubscriptionUpdateLinkReset())  
        } catch (error) {
            console.log(error);
        }
    }

  return (
    <div>
        <div className="dtitle">
                <div className="dboard">
                    <MdOutlineSubscriptions size={30} />
                    <p className='dboard__text'>Subscription</p>
                </div>
                <AiOutlineReload size={25} 
                                 color='#010A58' 
                                 cursor='pointer' 
                                 onClick={fetch} 
                />
        </div>
        <div className='dboard__container'>
        {
            loading ? <Loading label={'loading..'} />: 
            subscriptionTier === 'Free' ? (
                <div>
                    <AttributeContainer>
                        <AttributeField>Subscription Plan:</AttributeField>
                        <p>{subscriptionTier}</p>
                    </AttributeContainer>
                    <AttributeContainer>
                        <AttributeField>Free Trial Expiration:</AttributeField>
                        <p>{new Date(trialExpirationTimeStamp).toLocaleString()}</p>
                    </AttributeContainer>
                    <AttributeContainer>
                    <AttributeField>Status:</AttributeField>
                    <p style={{ 
                        color: subscriptionStatusFilter === 'active' ? 'green': 'red' }}>{subscriptionStatusFilter}</p>
                </AttributeContainer>
                </div>
            ): Object.keys(subscription).length ? (
        <div>
            <div>
                <AttributeContainer>
                    <AttributeField>Subscription Plan {currentSubscriptionTier ? `(Effective on ${next_payment_date.split('T')[0]})`: ''}:</AttributeField>
                    <p>{name}</p>
                </AttributeContainer>
                {
                    currentSubscriptionTier ? <AttributeContainer>
                                                    <AttributeField>Active Subscription Plan:</AttributeField>
                                                    <p>Name: {currentSubscriptionTier}</p>
                                                    <p>Ending: {next_payment_date.split('T')[0]}</p>
                                                </AttributeContainer>: null
                }
                <AttributeContainer>
                    <AttributeField>Subscription Plan Description:</AttributeField>
                    <p>{description}</p>
                </AttributeContainer>
                <AttributeContainer>
                    <AttributeField>Status:</AttributeField>
                    <p style={{ 
                        color: status === 'active' ? 'green': status === 'non-renewing' ? '#8B4000': status === 'attention' ? 'red': 'black' }}>{status}</p>
                    { status === 'non-renewing' ? <Description color='#8B4000'>Your Subscription Has Been Cancelled. Your Services will be cut off at the end of the Current Subscription. <br/> You can wait till your Current Subscription expires or you can create a New Subscription now.</Description>: 
                      status === 'attention' ? <Description color='red'>There was an Error (expired card or insufficent funds) trying to process the Subcription Payment. Your Services have been removed and the current Subscription is now null and void. To Offer Services create a new Subscription</Description>: null }
                      <CaptionsContainer>
                        <Caption>* Subscription status changes may take up to 30 minutes to reflect</Caption>
                      </CaptionsContainer>
                </AttributeContainer>
                <AttributeContainer>
                    <AttributeField>Amount (ZAR):</AttributeField>
                    <p>{amount/100}</p>
                </AttributeContainer>
                <AttributeContainer>
                    <AttributeField>Interval:</AttributeField>
                    <p>{interval}</p>
                </AttributeContainer>
                <AttributeContainer>
                    <AttributeField>{ status === 'active' ? 'Next Payment Date:': status === 'non-renewing' ? 'Services Cut-off Date': ''}</AttributeField>
                    <p>{next_payment_date.split('T')[0]} {next_payment_date.split('T')[1].split('.')[0]}</p>
                </AttributeContainer>
                <AttributeContainer>
                    <AttributeField>Features:</AttributeField>
                    {
                        subscriptionOptions.map((opt, idx) => {
                            return (
                                <Option key={idx}>
                                    <FaPlus size={10} />
                                    <p>{opt}</p>
                                </Option>
                            )
                        })
                    }
                </AttributeContainer>
                <div className="tower__progress">
                    { 
                    fetching ? <Uploading text="fetching link.." color={'black'} /> : 
                    renewing ? <Uploading text="Renewing Subscription.." color={'black'} /> : 
                    linkError ? <p className='error'>Error fetching Link. Try again</p> :
                    renewError ? <p className='error'>Error Renewing Subscription. Try again</p> :
                    plansError ? <p className='error'>Error Fetching Subscription Data. Try again</p> :
                    link ? <div style={{ textAlign: 'center' }}>
                        <p style={{ color: 'green' }}>Link fetched Successfully. Click Below:</p>
                        <a href={link} target="_blank" rel='noreferrer' style={{ color: 'blue' }}>Click Me!</a>
                    </div> : null
                    }
                </div>
            {
                isModalOpen ? <>
                { isModalOpen.type === 'update' ? (
                    <InteractiveModal height='90vh'> 
                        <div style={{ fontWeight: 700, textAlign: 'center', marginBottom: '10px' }}>Continue to Fetch Update Link?</div>
                        <div className="imodal__btns">
                            <FormButton background='#5282BD' 
                                        color='white' 
                                        onClick={updateCard}>
                                        Continue
                            </FormButton>
                            <FormButton borderRadius='5px' 
                                        onClick={() => setIsModalOpen({ value: false, type: '' })}>
                                            Cancel
                            </FormButton>
                        </div>
                    </InteractiveModal>
                    ) : isModalOpen.type === 'change' ? (
                        <InteractiveModal height='90vh'> 
                            <div style={{ fontWeight: 700, textAlign: 'center', marginBottom: '10px' }}>Change to {selectedTier?.plan} {checked.option === 'Next Payment Date' ? `on ${next_payment_date.split('T')[0]}`: 'Now'}?</div>
                            <div className="imodal__btns">
                                <FormButton background='#5282BD' 
                                            color='white' 
                                            onClick={changePlan}>
                                            Continue
                                </FormButton>
                                <FormButton borderRadius='5px' 
                                            onClick={() => setIsModalOpen({ value: false, type: '' })}>
                                                Cancel
                                </FormButton>
                            </div>    
                        </InteractiveModal>
                        ): null }
                </>
                : null}
        </div>
            </div>
            
        ): subscriptionError ? <p className='error'>Error Fetching Subscription Data. Try again later</p>: null }
         <div >
                    <div className="detail__item">
                    <Actions>
                        <i ><MdCallToAction size={25}/></i>
                        <p style={{ fontWeight: 'bold' }}>Actions</p>
                    </Actions>
                    <div className="detail__block">
                        <div className="action__btns">
                            { status === 'non-renewing' || subscriptionTier === 'Free' ? (
                                <>
                                    <div className="action__btn">
                                        <div className="action__text">
                                                <h3>Create a New Subscription</h3>
                                                <p>Create Subscription to Continue offering Services. You will be charged the Full Amount</p>
                                    </div>
                                        <Button onClick={() => createSubscription(null)} 
                                                borderRadius='7px' 
                                                background='#010A58' 
                                                color='white' 
                                                width='70px'
                                                border='none'>
                                                    Create
                                        </Button>
                                    </div>
                                </>
                            ): status === 'attention' ? (
                                <>
                                    <div className="action__btn">
                                        <div className="action__text">
                                                <h3>Create a New Subscription</h3>
                                                <p>Create another Subscription to Continue offering Services.</p>
                                    </div>
                                        <Button onClick={() => createSubscription({ subscription_code, email_token })} 
                                                borderRadius='7px' 
                                                background='#010A58' 
                                                color='white' 
                                                width='70px'
                                                border='none'>
                                                    Create
                                        </Button>
                                    </div>
                                </>
                            ): <>
                            <div className="action__btn">
                                <div className="action__text">
                                        <h3>Update Subscription</h3>
                                        <p>Make changes to your Subscription including changing subscription card and cancelling subscription</p>
                            </div>
                                <Button onClick={() => setIsModalOpen({ value: true, type: 'update' })} 
                                        borderRadius='7px' 
                                        background='#010A58' 
                                        color='white' 
                                        width='55px'
                                        border='none'>
                                            Edit
                                </Button>
                            </div>
                            <div className="action__btn">
                                <div className="action__text">
                                        <h3>Change Subscription Tier</h3>
                                        <p>Subscribe to a different Plan</p>
                            </div>
                                <Button 
                                        borderRadius='7px' 
                                        background='#010A58' 
                                        color='white' 
                                        width='55px'
                                        border='none'
                                        {...getUpdateToggleProps({ onClick: () => setIsUpdateExpanded(prev => !prev),})}>
                                            Edit
                                </Button>
                            </div>
                            </> }
                            <div {...getUpdateCollapseProps()}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                                { tiers && tiers.map((option, index) => {
                                    return (
                                        <Card key={index} >
                                                <Heading>
                                                    <TbCircleDotFilled color={option?.plan.includes('Basic') ? 'blue': option?.plan.includes('Standard') ? 'green': 'purple' } />
                                                    <p style={{ color: option?.plan.includes('Basic') ? 'blue': option?.plan.includes('Standard') ? 'green': 'purple', fontWeight: 'bold' }}>{option?.plan}</p>
                                                </Heading>
                                                <PriceContainer>
                                                    <Price>R{(option?.amount)}</Price>
                                                    <Interval>/ month </Interval>
                                                </PriceContainer>
                                                <OptionsContainer>
                                                    {option?.options.map((el,idx) => <Option key={idx}>
                                                                                <FaPlus size={10} />
                                                                                <p>{el}</p>
                                                                            </Option>)}         
                                                </OptionsContainer>
                                                <div style={{ marginTop: 40 }}>
                                                    <p>Effect changes on:</p>
                                                    {changeSubscriptionOptions.map((val,idx) => {
                                                        const { option, caption } = val
                                                        return <div key={idx} className='booking__radio-option'>
                                                                    <input type="radio" 
                                                                        value={option}
                                                                        name='service-tier'
                                                                        id={option}
                                                                        onClick={() => setChecked({ option, index })}  
                                                                    />
                                                                    <div>
                                                                        <label htmlFor={val}>{option}</label>
                                                                        <p style={{ color: 'blue', fontSize: 'x-small' }}>{caption}</p>
                                                                    </div>
                                                                </div>
                                                    })}
                                                </div>
                                                <ButtonContainer>
                                                <Button
                                                    background={option?.plan.includes('Basic') ? 'blue': option?.plan.includes('Standard') ? 'green': 'purple'}
                                                    color='white' 
                                                    border='none'
                                                    padding='15px'
                                                    borderRadius='5px'
                                                    width='200px'
                                                    disabled={!(checked?.index === index) || isBtnDisabled}
                                                    onClick={() => {
                                                        setSelectedTier(option)
                                                        setIsModalOpen({ type: 'change', value: true })}}>
                                                        Change
                                                </Button>
                                                </ButtonContainer>
                                            </Card>
                                    )
                                })}   
                                    </div>
                        {
                            changeSubscription.changing ? <Uploading text="Changing Subscription Plan.." color={'black'} /> : 
                            changeSubscription.error ? <p className='error'>Error Changing Subscription. Try again</p> : null
                        }
                    </div></div>
                </div>
            </div>
                </div>
        </div>
       
    </div>
  )
}

export default Subscription