import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BiStats } from 'react-icons/bi'
import useCollapse from 'react-collapsed'
import { useAuth0 } from '@auth0/auth0-react'
import { fetchRoadsideAssistMetrics } from '../../Components/dashboard/redux/metrics.slice'
import { Loading } from '../../Components/loader/loader'

function RoadsideAssistStats({companyID}) {
    const { towers } = useSelector(state => state.towers)
    const { loading, metrics, metricsError } = useSelector(state => state.dashboardMetrics)
    const { user: { sub }, getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch()

    const { requestsByType, requestsByTower  } = metrics
  
    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE
                })
                dispatch(fetchRoadsideAssistMetrics(companyID,sub,accessToken))
                } 
            catch (error) {
                console.log(error);
                }
        }

        if (towers.length) {
            fetchMetrics()
        }
        
    },[])


    const dateRange = () => {
        const min = new Date().getMonth() - 1
        const year = new Date().getFullYear()
        return `${min}/${year} - ${new Date().getMonth()}/${year}`
    }

  return (
    <>
        {
            !towers.length ? (
                <div className="stats__container dboard__container">
                    <p className="stats__blockt">No Towing Metrics</p>
                </div>
            ): loading ? <Loading loading={loading} />:   Object.keys(metrics).length ? (
                <div className='stats'>
        <div className="stats__header">
            <div className="dboard">
                <BiStats size={60} color={'#010A58'} />
                <p className='dboard__text'>Stats</p> 
            </div>
            <p className='stats__heading'>Statistics for {dateRange()}</p>
        </div>
        <div className="stats__container dboard__container"> 
            <div className="stats__block">
                <p className="stats__blockt">Towing Metrics</p>
                <div className="stats__total">
                    <p className='stats__text'>Total Number of Towers</p>
                    <p className='stats__value'>{requestsByTower ? Object.keys(requestsByTower).length : '-'}</p>
                </div>
                <div className="stats__row">
                    <div className="stats__option">
                        <p className='stats__text'>Total Number of Requests</p>
                        <p className='stats__value'>{requestsByType?.request}</p>    
                    </div>
                    <div className="stats__option">
                        <p className='stats__text'>Acceptance Ratio</p>
                        <p className='stats__value'>{(requestsByType?.accept/requestsByType?.request).toFixed(2)}</p>
                    </div>   
                </div>
            </div>
            <div className="stats__block">
                <p className="stats__title">
                    Requests states
                </p>
                <div className="stats__row">
                    {requestsByType ? Object.entries(requestsByType).map((obj,idx) => {
                        const [type, count] = obj
                        if (type !== 'request') {
                            return (
                                <div key={idx} className="stats__option">
                                    <p className='stats__text'>{type.toUpperCase()}</p>
                                    <p className={`stats__value ${'stats__'.concat(type)}`}>{count}</p>
                                </div>
                            )
                        }
                    }) : null }          
                </div>

            </div>
            <div className="stats__block">
                <div className="stats__trow-mod stats__h">
                    <p className="stats__title">
                        Tower Name 
                    </p>
                    <div className="stats__values">
                        {['Requests','Accepted','Rejected','Ignored','Acceptance Ratio'].map((heading,idx) => {
                            return (
                                <div key={idx} className="stats__values-container">
                                    <p className='stats__val'>{heading}</p>
                                </div>
                            )
                        })}       
                    </div>
                </div>
                {   requestsByTower ? 
                    Object.entries(requestsByTower).map(tower => {
                        const [name, { accept, noreply, reject, request }] = tower
                        
                        return (
                            <div className="stats__trow-mod">
                                <p className="stats__title">
                                    {name}
                                </p>
                                <div className="stats__row">
                                    <div  className="stats__values-container">
                                        <p className='stats__val'>{request}</p>
                                    </div>  
                                    <div  className="stats__values-container">
                                        <p className='stats__val'>{accept?accept:0}</p>
                                    </div>
                                    <div  className="stats__values-container">
                                        <p className='stats__val'>{reject?reject:0}</p>
                                    </div>
                                    <div  className="stats__values-container">
                                        <p className='stats__val'>{noreply?noreply:0}</p>
                                    </div>
                                    <div  className="stats__values-container">
                                        <p className='stats__val'>{Math.round(accept*10/request)/10}</p>
                                    </div>       
                                </div>
                            </div>
                        )
                    }) : null
                }
            
                {/* <div className="stats__block">
                    <p className="stats__blockt">
                        Customer Feedback
                    </p>
                    <div className="stats__reviews">
                        <div className="stats__rv">
                            <p className="stats__rn">
                                {totalReviews}
                            </p>
                            <p className="stats__rt">
                                Reviews
                            </p>
                        </div>
                        <div className="stats__meters">
                            {[["five",5],["four",4],["three",3],["two",2],["one",1]].map((val,idx) => {
                                const percent = ratingsCount[val[0]] / 5
                                const starCategory = val[1]
                                return (
                                    <Meter starCategory={starCategory} percent={percent} 
                                           width={250} height={15} rounded={true} />
                                )
                            })}
                        </div>    
                    </div>
                    { allReviews.length ?  <div className="stats__rl" {...getToggleProps({
                                                  onClick: () => setIsReviewsExpanded(prev => !prev),
                                                })} >
                                                <p>{isReviewsExpanded ? 'Hide Reviews' : 'Show Reviews'}</p>
                                                { isReviewsExpanded ? <MdExpandLess size={20} color='black' /> : <MdExpandMore size={20} color={'black'} /> }
                                            </div> : null }
                    <div className="stats__reviews-list" {...getCollapseProps()}>
                            {
                                allReviews.map((rev,idx) => {
                                    const { consumername, rating, review } = rev
                                    return <Review key={idx} consumername={consumername} rating={rating} review={review} />
                                })
                            }
                    </div>
                    
                </div> */}
                
            </div>
        </div>
    </div>
            ) : null
        }
    </>
  )
}

export default RoadsideAssistStats