import React, { useState, useRef, useEffect } from 'react'
import {AiOutlineBulb} from 'react-icons/ai'
import {BsQuestionSquare} from 'react-icons/bs'
import {RiUserSmileLine} from 'react-icons/ri'
import GoogleMapReact from 'google-map-react';
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { isAndroid, isIOS, isMobile } from 'react-device-detect'

import {InformativeFeature} from '../../Components/feature/feature';
import { InteractiveModal, QRCodeModal } from '../../Components/modal/Modal';
// import { MiniNav } from '../../Components/slider/slider';
import Footer from '../../Components/footer/footer'
import ImageCardWithText, { WhatWeOfferDesktop, WhatWeOfferMobile } from '../../Components/imageCardWithText/imageCardWithText';

export const DownloadText = styled.p`
    padding: 8px 10px;
    background: #009dfe;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    color: white;
`

export const DownloadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px auto;
    gap: 10px;
    @media (min-width: 900px) {
        width: 50%;
      }
`

export const QRCodeContainer = styled.div`
    width: 200px;
    height: 200px;
`

export const QRCodeImage = styled.img`
    width: 100%;
    height: 100%;
`

export const LinksContainer = styled.div`
    @media (min-width: 900px) {
        display: flex;
      }
`

export const Title = styled.p`
font-weight: 700;
    
    font-size: 18px;
    line-height: 1.2;
`

export const Description = styled.p`
    text-align: center;
    font-size: 14px;
    max-width: 300px;
    @media (min-width: 900px) {
        max-width: 1000px;
      }
`

const Container = styled.div`
    position: relative;
`

const Placeholder = styled.div`
height: 250px;
`

const features = [
    { icon: <AiOutlineBulb />,text:'Make a booking in seconds.',heading:'Convenience' },
    { icon:<RiUserSmileLine />,text:'Easy to use and very user friendly.',heading:'User friendly'},
    { icon:<BsQuestionSquare />,text:"We're here 24/7 to help you.",heading:'Got any questions?'}
]

// const subMenuLinks = [
//     {heading:'Roadside Assist',img:'/rassist.webp'},
//     {heading:'Vehicle maintenance',img:'/oil-horizontal.webp'},
// ]

export const AppLinks = ({ title, links, description, setIsModalOpen, setImage }) => {
    const history = useHistory()

    const openApp = (platform) => {
        if (isMobile) {
            if (isAndroid) {
                window.location.href = `https://play.app.goo.gl/?link=${links.android}`;
            } else if (isIOS) {
                window.open(links.ios, "_blank", "noreferrer");
            }
        } else {
            if (title === 'TapAService') {
                if (platform === 'android') {
                    setImage('./android-tap-client-qrcode.png')
                } else {
                    setImage('./ios-tap-client-qrcode.png')
                }
            } else {
                if (platform === 'android') {
                    setImage('./android-tap-driver-qrcode.png')
                } else {
                    setImage('./ios-tap-driver-qrcode.png')
                }
            }
            setIsModalOpen(true)

        }
    }

    return (
        <DownloadContainer>
            <Title>{title}</Title>
            <Description style={{ textAlign: 'center' }}>{description}</Description>
            <DownloadText>Click Below to Download</DownloadText>
            <div className="app__links">
                {/* <div onClick={() => openApp('android')} className='app__android' /> */}
                {/* <div className='app__ios' /> */}
                { isMobile && isAndroid ? <div onClick={() => openApp('android')} className='app__android' />:
                  isMobile && isIOS ? <div onClick={() => openApp('ios')} className='app__ios' />:
                  <>
                        <div onClick={() => openApp('ios')} className='app__ios' />
                        <div onClick={() => openApp('android')} className='app__android' />
                  </>}
            </div>
        </DownloadContainer>
    )
  }

function BelowFold() {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [image, setImage] = useState('')
    const homeEndRef = useRef(null);

    useEffect(() => {
        if (isModalOpen) {
            // window.scrollTo({
            //     top: homeEndRef.current.offsetTop,
            //     behavior: 'smooth',
            // })
            homeEndRef.current?.scrollIntoView({ behavior: "smooth" })
        }
    },[isModalOpen])

  return (
    <Container>
        <ImageCardWithText image={'/image-1.jpeg'} data-test="image-card-with-text"
                            heading="BRINGING POWER TO THE CONSUMERS"
                            text={["Maintain and look after your vehicle using your smartphone."," Find Car Servicing Professionals in your vicinity and make Service Bookings in a matter of minutes.", "Stuck somewhere? Don't worry, find Realtime Roadside Assistance around you with the click of a button.", "We're literally bringing the power into your hands."]}
        />
        <WhatWeOfferDesktop image={'/image-2.jpeg'} data-test="image-card-with-text"
                            heading="WHAT WE OFFER"
                            text="Maintain and look after your vehicle using your smartphone. We're literally bringing the power into your hands."
        />
        <WhatWeOfferMobile image={'/image-2.jpeg'} data-test="image-card-with-text"
                            heading="WHAT WE OFFER"
                            text="Maintain and look after your vehicle using your smartphone. We're literally bringing the power into your hands."
        />
        {/* <MiniNav links={subMenuLinks} heading="Service Providers options" data-test='mini-nav' /> */}
        {/* <div className="info__features" data-test="info__features">
            {features.map(({icon,text,heading},idx)=>{
                return <InformativeFeature key={idx} icon={icon} text={text} heading={heading} />
            })}
        </div> */}
        {/* <AppLinks /> */}
        <LinksContainer>
            <AppLinks 
            setIsModalOpen={setIsModalOpen} 
            links={{ 
                android: 'https://play.google.com/store/apps/details?id=com.tapaservice.client',
                ios: 'itms-apps://apps.apple.com/us/app/tapaservice/id6737030857' 
            }} 
            title={'TapAService'} 
            description={'Find Realtime Roadside Assistance & Make Car Service Bookings'} 
            setImage={setImage}
            />
            <AppLinks 
            setIsModalOpen={setIsModalOpen} 
            title={'TAS Driver'} 
            setImage={setImage}
            links={{ 
                android: 'https://play.google.com/store/apps/details?id=com.tapaservice.driver', 
                ios: 'itms-apps://apps.apple.com/us/app/tas-driver/id6737838374'
            }} 
            description={'Own/Work for a Registered Towing Company? Get Roadside Assistance Requests in Realtime'} 
            />
        </LinksContainer>
        { isModalOpen ? <QRCodeModal height={'100vh'} setIsModalOpen={setIsModalOpen} style={{ position: 'absolute', width: '100%', bottom: 0 }} >
            <QRCodeContainer>
                <QRCodeImage src={image} alt="card-icon" loading="lazy" />
            </QRCodeContainer>
        </QRCodeModal>: null }
         <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyAG2L_SwgYlgPy4OnzCoADp0Z8v-OI9hvk", language: 'en-us' }}
                        defaultZoom={11}
                    >
            </GoogleMapReact>
        <Footer />
        <div ref={homeEndRef}></div>
    </Container>
  )
}

export default BelowFold