import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'

const Button = styled(Link)`
  position: absolute;
  padding: 15px;
  top: 230px;
  background: rgb(0, 157, 254);
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  transition: transform 0.2s ease-in;
  &:hover {
    transform: scale(1.05);
  }
`


export const FeaturesWithImages = React.memo(({features}) => {
    const history = useHistory()
  return (
    <div className='fwimage'>
        {features.map((feature,index) => {
            const { link, img } = feature
            return (
                <article onClick={() =>link && history.push(link)} style={{...(link && { cursor: 'pointer'}), position: 'relative' }} key={index} className="fwimage__feature">
                    <img width='240' height='214' className="fwimage__img" src={img} alt="feature-icon" loading="lazy"/>
                    { index === 0 ? <Button to='/services'>View Services</Button>: 
                      index === 1 ? <Button to='/services'>Create Booking</Button>: 
                      index === 2 ? <Button to='/signup'>Sign Up Now</Button>: null }
                </article>
            )
        })}
  </div>
  )
})

export const InformativeFeature = React.memo(({icon,text,heading}) => {
    return (
        <div className='info__feature'>
          <div className='info__h' >
            <i className="info__i fa fa-2x">{icon}</i>
            <h3 className='info__h3'> {heading} </h3>
          </div>
          <p className='info__p'>{text}</p>
        </div>
    )
})


