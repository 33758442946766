import React, { useCallback, useMemo } from 'react'
import {useDropzone} from 'react-dropzone'
import { fileCompressor } from '../../utils/compressor';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const focusedStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const containerStyle = {
    marginTop: '20px',
    marginBottom: '20px'
  }

export function ImagesDropZone({ handleImagesChange }) {
    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
      const compressPromises = [];
      if (!acceptedFiles) {
        return;
      }
      for (const file of acceptedFiles) {
          compressPromises.push(fileCompressor(file));
      }
      Promise.all(compressPromises)
      .then((compressedFiles) => {
        handleImagesChange(compressedFiles)
      })
      .catch((error) => console.log('ooops :(', error))
      }, [])

    const { getRootProps, getInputProps,
            isFocused,isDragAccept,isDragReject } = useDropzone({onDrop,accept: {'image/*': []},maxFiles: 10, maxSize: 200000, multiple: true })

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
        }), [
        isFocused,
        isDragAccept,
        isDragReject
        ]);

  return (
    <div style={containerStyle}>
        <div {...getRootProps({style})}>
            <input {...getInputProps()} />
          <p>Drag 'n' drop some pictures here, or click to select (10 max, 200kB max size)</p>
          <i>(only images will be accepted: png, webp, jpg, jpeg)</i>
    </div>
    </div>
  )
}

export function VideoDropZone({ handleVideoChange }) {
    const onDrop = useCallback((acceptedFiles) => {
      handleVideoChange(acceptedFiles[0])  
    }, [])

    const { getRootProps, getInputProps,
            isFocused,isDragAccept,isDragReject } = useDropzone({onDrop,accept: {'video/mp4': []},maxFiles: 1, maxSize: 15000000})

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
        }), [
        isFocused,
        isDragAccept,
        isDragReject
        ]);

    return (
        <div style={containerStyle}>
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop your video here, or click to select</p>
                <i>(1 .mp4 video file (480p) with a max file size of 15mb)</i>
        </div>
        </div>
        )
}

export function CertificatesDropZone({ handleFilesChange }) {

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
      handleFilesChange(acceptedFiles)
    }, [])

  const { getRootProps, getInputProps,
          isFocused,isDragAccept,isDragReject } = useDropzone({onDrop,accept: {'application/pdf': []}})

  const style = useMemo(() => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
      }), [
      isFocused,
      isDragAccept,
      isDragReject
      ]);

return (
  <div style={containerStyle}>
      <div {...getRootProps({style})}>
          <input {...getInputProps()} />
        <p>Drag 'n' drop documents here, or click to select files</p>
        <i>(only .pdf will be accepted)</i>
  </div>
  </div>
)
}

