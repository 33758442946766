import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../../redux/axios/axios'

const initialState = {
  saving : false,
  savedTransferRecipient: false,
  saveTransferRecipientError: ''
}

const saveTransferRecipient = createSlice({
  name: 'saveTransferRecipient',
  initialState,
  reducers: {
    saveTransferRecipientRequest(state) {
      state.saving = true
    },
    saveTransferRecipientSuccess(state) {
        state.saving = false
        state.savedTransferRecipient = true
    },
    saveTransferRecipientError(state, action) {
      state.saving = false
      state.saveTransferRecipientError = action.payload
    },
  }
})

export const { saveTransferRecipientRequest, saveTransferRecipientSuccess, saveTransferRecipientError } = saveTransferRecipient.actions

export const saveTransferRecipientCode = (postData, token) => async (dispatch) => {
  dispatch(saveTransferRecipientRequest())
  try {
      await customAxios.post('/paystack/save-recipient-code',{ ...postData },{
                                                                             headers: {
                                                                                 Authorization: `Bearer ${token}`
                                                                              }
                                                                          })
      dispatch(saveTransferRecipientSuccess())
  } catch(err)  {
      console.log(err);
      dispatch(saveTransferRecipientError(err.message))
  }      
}



export default saveTransferRecipient.reducer
