import React, { useEffect, useState, Suspense, lazy } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MdPending, MdCheckBox, MdLineWeight, MdChatBubble, MdManageAccounts, 
         MdOutlineSupervisedUserCircle, MdVerified, MdCancelPresentation, MdOutlineSubscriptions } from 'react-icons/md'
import { BiMoneyWithdraw } from 'react-icons/bi'
import { GiTowTruck } from 'react-icons/gi';
import { BsArrowRightSquare, BsBank } from 'react-icons/bs'
import { FaUserCheck } from 'react-icons/fa'
import { FiAlertCircle } from "react-icons/fi";
import { GoAlertFill } from "react-icons/go";
import styled from 'styled-components';
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet-async';

import { Loading } from '../../Components/loader/loader';
import { fetchVehicleMaintenanceProfile, fetchRoadsideAssistProfile } from '../../Components/dashboard/redux/profile.detail.slice';
import { FormButton } from '../../Components/button/Button';
import RoadsideAssistSkeleton from '../../Components/skeletons/ra-dashboard';
import VehicleMaintenanceSkeleton from '../../Components/skeletons/vm-dashboard';
import { InteractiveModal } from '../../Components/modal/Modal';
import DashboardProfileVerification from '../../Components/dashboard/views/Verification';
import RoadsideAssistStats from '../analytics/RoadsideAssist';
import ManageAccount from '../../Components/dashboard/views/ManageAccount';
import Subscription from '../../Components/dashboard/views/Subscription';
import TransferRecipient from '../../Components/dashboard/views/BankDetails';
import { fetchBankingDetails } from '../../Components/dashboard/redux/banking-details.slice';
import { setProfileType, resetProfileType } from '../../Components/dashboard/redux/profile.detail.slice';

const ViewDashboardProfile = lazy(() => import(/* webpackChunkName: "dashboard-profile" */ '../../Components/dashboard/views/ViewProfile'))
const BookingUpdate = lazy(() => import(/* webpackChunkName: "booking-update" */ '../../Components/dashboard/views/BookingUpdate'))
const Towers = lazy(() => import(/* webpackChunkName: "towers" */ '../../Components/dashboard/views/Towers'))
const DashboardChat = lazy(() => import(/* webpackChunkName: "dashboard-chat" */ '../../Components/chat/chat'))
const Payments = lazy(() => import(/* webpackChunkName: "dashboard-chat" */ '../../Components/dashboard/views/Payments'))


const alert = {
    background: 'black',
    color: 'white',
    textAlign: 'center',
    padding: '15px',
    marginBottom: '1px',
    fontWeight: '600'
}

export const DashboardNoteContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 100px;
    padding: 5px;
`

export const DashboardNote = styled.p`
    text-align: center;
    line-height: 2;
    font-size: 16px;
`

function Dashboard() {
    const {loading, profile, profileError} = useSelector(state => state.dashboardProfile)
    const { bankingDetails, bankingDetailsError, loading: bankingDetailsLoading} = useSelector(state => state.bankingDetails)
    const { created } = useSelector(state => state.createProfileDeletion)
    const { created: createdAccountDeletion } = useSelector(state => state.createAccountDeletion)
    const {profileType } = useSelector(state => state.selectedProfileType)
    const [active, setActive] = useState({})
    const [btnDisable, setBtnDisable] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [companyID, setCompanyID] = useState('')
    const { user, getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        const fetchDetails = async() => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE
                })
                dispatch(fetchBankingDetails(user.email, accessToken))
            } catch (error) {
                alert('Error fetching your Banking Details. Try again later')
            }
        }
        
        fetchDetails()
        return () => {
            dispatch(resetProfileType())
        }
    },[])

    useEffect(() => {
        const fetch = async() => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE
                })
                if (profileType.type === 'Vehicle Maintenance') {
                    history.push('?profile_type=car_service')
                    dispatch(fetchVehicleMaintenanceProfile(user.email, accessToken))
                    setActive({ screen:'Messages', view:<Suspense fallback={<Loading loading={true}/>}>
                                                            <DashboardChat/>
                                                        </Suspense> })
                } else if (profileType.type === 'Roadside Assist') {
                    history.push('?profile_type=towing')
                    dispatch(fetchRoadsideAssistProfile(user.email, accessToken, profileType.companyID ))
                    setActive({ screen:'Towers', view:  <Suspense fallback={<Loading loading={true}/>}>
                                                            <Towers companyID={profileType.companyID} />
                                                        </Suspense> })
                }    
            } 
            catch (error) {
                console.log(error);
            }
        }
        if (profileType) {
            fetch()
        }
    },[profileType, created, createdAccountDeletion])

    const createServiceRedirect = () => {
        history.push('/services/create')
    }

    const openProfile = () => {
        setActive({ screen: 'Profile', view: <Suspense fallback={<Loading loading={true}/>}>
                                                <ViewDashboardProfile   setActive={setActive} 
                                                                        
                                                                        type={profileType.type}
                                                                        companyID={profileType.companyID} 
                                                />
                                            </Suspense> })
    }

    const openBookings = (screen) => {
        setActive({ screen, view: <Suspense fallback={<Loading loading={true}/>}>
                                        <BookingUpdate state={screen.toUpperCase()} companyName={profile.companyName}/>
                                    </Suspense>})
    }

    const openPayments = (screen) => {
        setActive({ screen: 'Payments', view:   <Suspense fallback={<Loading loading={true}/>}>
                                                    <Payments companyID={profileType.type === 'Roadside Assist' ? profileType.companyID: user.email} 
                                                    title={profileType.type === 'Roadside Assist' ? 'Requests Payments': 'Booking Payments'}
                                                    isRoadsideAssistProfile={profileType.type === 'Roadside Assist' ? true : false } />
                                                </Suspense>})
    }

    const openChat = () => {
        setActive({ screen:'Messages', view: <Suspense fallback={<Loading loading={true}/>}>
                                                <DashboardChat/>
                                            </Suspense> })
    }

    const openBanking = () => {
        setActive({ screen:'Banking', view: <Suspense fallback={<Loading loading={true}/>}>
                                                <TransferRecipient/>
                                            </Suspense> })
    }

    const openTowers = () => {
        setActive({ screen: 'Towers', view: <Suspense fallback={<Loading loading={true}/>}>
                                                <Towers companyID={profileType.companyID} />
                                            </Suspense>})
    }

    const openVerification = () => {
        setActive({ screen: 'Verification', view: <DashboardProfileVerification profileType={profileType}  /> })
    }

    const openSubscription = () => {
        setActive({ screen: 'Subscription', view: <Subscription profileType={profileType} setActive={setActive}  /> })
    }

    const openManageAccount = () => {
        setActive({ screen: 'Account', view: <ManageAccount /> })
    }

    const selectRoadsideAssist = () => {
        setBtnDisable(true)
        dispatch(setProfileType({ type: 'Roadside Assist', companyID}))
    }

    const openStats = () => {
        setActive({ screen: 'Stats', view: <RoadsideAssistStats companyID={profileType.companyID} /> })
    }

    const handleOnChange = (e) => {
        const { value } = e.target
        setCompanyID(value)
    }

  return (
      <>
            {
                isMobile ? <DashboardNoteContainer>
                    <DashboardNote>Dashboard is not supported on Mobile View. Please open this page on your laptop/desktop</DashboardNote>
                </DashboardNoteContainer>:
                !Object.keys(profileType).length ? (
                    <div className="service-options__container">
                <div className='service-options profile-t__options'>
                            <div className='profile-t__option profile-t__header'>
                                <h1 style={{ fontSize: '24px', fontWeight: 700 }} >Which Dashboard Profile Do you want to Open?</h1> 
                            </div>
                            <div className="service-options__block profile-t__option" >
                            <div className="service-options__item">
                                <div data-test='car-service-profile' className='service-options__link profile-t__type'
                                     onClick={() => dispatch(setProfileType({ type: 'Vehicle Maintenance'}))}  >    
                                        <div className='service-options__grp'>
                                            <p className='service-options__text'>Vehicle Maintenance</p>
                                            <BsArrowRightSquare />             
                                    </div>   
                                </div>
                            </div>
                            </div>
                            <div className="service-options__block profile-t__option" >
                            <div className="service-options__item">
                                <div data-test='roadside-profile' className='service-options__link profile-t__type' 
                                     onClick={() => setIsModalOpen(true)} >         
                                        <div className='service-options__grp'>
                                            <p className='service-options__text'>Roadside Assist</p>
                                            <BsArrowRightSquare />                
                                    </div>   
                                </div>
                            </div>
                            </div>
                        </div>
                        {
                            isModalOpen ? (
                                <InteractiveModal height='90vh'> 
                                    <div style={{ fontWeight: 700, textAlign: 'center', marginBottom: '10px' }}>Enter your Company ID</div>
                                    <form onSubmit={selectRoadsideAssist}>
                                        <input type="text" 
                                                onChange={handleOnChange} 
                                                placeholder='company ID'
                                                className='imodal__input'
                                        />
                                    </form>
                                    <div className="imodal__btns">
                                        <FormButton background='#5282BD' 
                                                    color='white' 
                                                    onClick={selectRoadsideAssist}>
                                                    Continue
                                        </FormButton>
                                        <FormButton borderRadius='5px' 
                                                    onClick={() => setIsModalOpen(false)}>
                                                        Cancel
                                        </FormButton>
                                    </div>
                                    
                                </InteractiveModal>
                                ) : null 
                            }
                        </div>
                        ) : (
        <div>
          {
          loading && profileType.type === 'Roadside Assist' ? <RoadsideAssistSkeleton /> : 
          loading && profileType.type === 'Vehicle Maintenance' ? <VehicleMaintenanceSkeleton /> : profileError ? 
          <article className='dashboard__notif'>{profileError.message}</article> :
          !Object.keys(profile).length ? (
            <article className='dashboard__notif'>
                <p>Profile not found!</p>
                <p className='dashboard__link' onClick={createServiceRedirect}>Click here to Create profile </p>
            </article>)   : profile.verified === 'delete' ? (
                <article className='dashboard__notif'>
                <p style={{ fontSize: '20px' }}>Your Service Provider Profile is under Review for Deletion</p>
            </article>
            ):
            (
            <>
            { 
                (profile.subscriptionTier === 'Free') ? 
                <p style={alert}>{Date.now() >= profile.trialExpirationTimeStamp ? `Your Free Trial Expired on ${new Date(profile.trialExpirationTimeStamp).toLocaleString()}. Your Services have been disabled`: `Your Free Trial Expires on: ${new Date(profile.trialExpirationTimeStamp).toLocaleString()}`}</p>: null
            }
            { 
                (!profile.images.length || !profile.video || !profile.logo ) ? 
                <p style={alert}>Profile missing some or all assets (logo,pictures,video). 
                   It wont be visible on listings page till you add them. Go to Edit Profile section to Add them</p>: null
            }
            {
                profile.verified === 'false' ? <p style={alert}>Your Profile has not been verified. 
                                                It won't be visible on listings page till you upload all 
                                                the required documents under the Verification section</p> :
                profile.verified === 'expired' ? <p style={alert}>Your Profile Documents have expired. 
                                                Your Profile won't be visible on listings page till you upload new 
                                                documents under the Verification section</p> :
                profile.verified === 'pending' ? <p style={alert}>Your Profile verification is in progress.</p> : null
            }
            
            <div className="dashboard">
                <div className='dashboard__sidebar'>
                    <div className="dashboard__items">
                        <div className="dashboard__item">
                            <div className='dashboard__option-header'><p><span style={{ fontWeight: 300, fontSize: 'small' }}>Hello,</span> {user.email}</p></div>
                        </div>
                        <div className="dashboard__item">
                                <p className='dashboard__option-header'>Banking</p>
                                <div id='Pending' className={`dashboard__option ${active.screen === 'Banking' ? 'dashboard__active' : null }`} onClick={()=>openBanking()}>
                                    <BsBank size={30} />
                                    <p className="dashboard__option-text">Banking Details</p>
                                    <div>
                                    { !bankingDetails && !bankingDetailsLoading ? <GoAlertFill size={20} color='red' />: null }
                                    </div>
                                </div>
                        </div>
                        { profileType.type === 'Vehicle Maintenance' ? (
                            <div className="dashboard__item">
                                <p className='dashboard__option-header'>Bookings</p>
                                <div id='Pending' className={`dashboard__option ${active.screen === 'Cancelled' ? 'dashboard__active' : null }`} onClick={()=>openBookings('Cancelled')}>
                                    <MdCancelPresentation size={30} />
                                    <p className="dashboard__option-text">Cancelled</p>
                                </div>
                                <div id='Pending' className={`dashboard__option ${active.screen === 'Pending' ? 'dashboard__active' : null }`} onClick={()=>openBookings('Pending')}>
                                    <MdPending size={30} />
                                    <p className="dashboard__option-text">Pending</p>
                                </div>
                                <div className={`dashboard__option ${active.screen === 'InProgress' ? 'dashboard__active' : null }`} onClick={()=>openBookings('InProgress')}>
                                    <MdLineWeight size={30} />
                                    <p className="dashboard__option-text">InProgress</p>
                                </div>
                                <div className={`dashboard__option ${active.screen === 'Completed' ? 'dashboard__active' : null }`} onClick={()=>openBookings('Completed')}>
                                    <MdCheckBox size={30} />
                                    <p className="dashboard__option-text">Completed</p>
                                </div>
                                <div className={`dashboard__option ${active.screen === 'Collected' ? 'dashboard__active' : null }`} onClick={()=>openBookings('Collected')}>
                                    <FaUserCheck size={28} />
                                    <p className="dashboard__option-text">Collected</p>
                                </div>
                            </div>) : null }
                        { profileType.type === 'Roadside Assist' ? (
                            <>
                             <div className="dashboard__item">
                                <div className={`dashboard__option ${active.screen === 'Towers' ? 'dashboard__active' : null }`} onClick={openTowers} >
                                    <GiTowTruck size={30} />
                                    <p className="dashboard__option-text">Towers</p>
                                </div>
                            </div>
                            <div className="dashboard__item">
                                <div className={`dashboard__option ${active.screen === 'Stats' ? 'dashboard__active' : null }`} onClick={openStats} >
                                    <MdVerified size={30} />
                                    <p className="dashboard__option-text">Stats</p>
                                </div>
                            </div>
                            </>
                            )  : null }
                        { profileType.type === 'Vehicle Maintenance' ? (
                            <div className="dashboard__item">
                                <div className={`dashboard__option ${active.screen === 'Messages' ? 'dashboard__active' : null }`} onClick={openChat} >
                                    <MdChatBubble size={30} />
                                    <p className="dashboard__option-text">Messages</p>
                                </div>
                            </div>) : null }
                        <div className="dashboard__item">
                            <div className={`dashboard__option ${active.screen === 'Verification' ? 'dashboard__active' : null }`} onClick={openVerification} >
                                <MdVerified size={30} />
                                <p className="dashboard__option-text">Verification</p>
                            </div>
                        </div> 
                        <div className="dashboard__item">
                            <div className={`dashboard__option ${active.screen === 'Payments' ? 'dashboard__active' : null }`} onClick={openPayments}>
                                <BiMoneyWithdraw size={30} />
                                <p className="dashboard__option-text">Payments</p>
                            </div>
                        </div>
                        <div className="dashboard__item">
                            <p className='dashboard__option-header'>Account</p>
                            <div className={`dashboard__option ${active.screen === 'Profile' ? 'dashboard__active' : null }`} onClick={openProfile}>
                                <MdOutlineSupervisedUserCircle size={30} />
                                <p className="dashboard__option-text">Manage Profile</p>
                            </div>
                            <div className={`dashboard__option ${active.screen === 'Account' ? 'dashboard__active' : null }`} onClick={openManageAccount}>
                                <MdManageAccounts size={30} />
                                <p className="dashboard__option-text">Manage Account</p>
                            </div>
                            <div className={`dashboard__option ${active.screen === 'Subscription' ? 'dashboard__active' : null }`} onClick={openSubscription}>
                                <MdOutlineSubscriptions size={30} />
                                <p className="dashboard__option-text">Subscription</p>
                                {profile.subscriptionStatusFilter !== 'active' ? <FiAlertCircle color='red' size={20} />: null }
                            </div>
                        </div>     
                    </div>
                    <div className="dashboard__content"></div>
                </div>
        <div className="dashboard__body">
            
            <div className="dashboard__content">
                <div className="dashboard__view">
                    {active.view}
                </div>
            </div>
        </div>
        </div>
            <Helmet>
                <title>Dashboard</title>
                <meta name='description' content='TapAService Service Provider Dashboard' />
            </Helmet>
        </>
            )} 
    </div>
    )}
    </>
  )
    
}

export default Dashboard