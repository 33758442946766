import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import { BiMessageSquareDots } from 'react-icons/bi'
import { Helmet } from 'react-helmet-async';

import { SPProfile } from '../../Components/profile/profile'
import { useGetChatsList } from '../../utils/client-graphql-hooks'
import { Chat } from '../../Components/chat/client-chat'

const SkeletonContainer = styled.div`
  width: 100%;
  height: 100%;
`

const ChatsContainer = styled.div`
padding: 10px;
text-align: center;
position: relative;
height: calc(100vh - 38px);
@media (min-width: 850px) {
    text-align: left;
};
@media (min-width: 1100px) {
    width: 95%;
    margin: auto;
};
`

const ClientChat = styled.div`
    height: 100%;
    @media (min-width: 850px) {
        display: grid;
        grid-template-columns: 2fr 3fr;
        align-items: flex-start;
        justify-content: space-between;
        gap: 20px;
      }
    
`

const ChatsList = styled.div`
    width: 100%;
    height: 100%;
    overflow: scroll;
    border-radius: 15px;
`

const InitialScreenContainer = styled.div`
    display: none;
    @media (min-width: 850px) {
        display: block;
      }
`

const Heading = styled.p`
      margin: 15px 0;
      font-weight: 600;
      font-size: 22px;
`

const NoChats = () => {

    return (
            <div className="dinit">
                <div className="dinit__container">
                <p>No chats...yet</p>
                </div>
            </div>
    )
  }

const InitialChatScreen = () => {

return (
    <InitialScreenContainer>
        <div style={{ background: '#f9f9f9'}} className="dinit client-chat">
        <div className="dinit__container">
            <p>Click on any chat to view messages</p>
            <BiMessageSquareDots size={30}/>
        </div>
        </div>
    </InitialScreenContainer>
)
}

function Chats() {
    const { state } = useLocation()
    const [isChatOpen, setIsChatOpen] = useState(false)
    const [active, setActive] = useState(null)
    const [receiver, setReceiver] = useState(null)
    const [openChatCreds, setOpenChatCreds] = useState(null)
    const email = state?.email
    const { chats: { items,loading } } = useGetChatsList(email)

    const openChat = (email,index) => {
        setReceiver(email)
        setIsChatOpen(true)
        setActive(index)
      }
    
  return (
    <ChatsContainer>
        <Heading>Chats</Heading>
        <ClientChat>
            <ChatsList>
            { loading ? <SkeletonContainer>
                            <Skeleton height='100%' />
                        </SkeletonContainer> :
                !items.length ?  <NoChats /> : 
                items.map((email,idx)=> <SPProfile key={email}
                                                    email={email}
                                                    index={idx}
                                                    openChat={openChat}
                                                    setOpenChatCreds={setOpenChatCreds}
                                                    active={active} 
                                            /> )}
            </ChatsList>
            { isChatOpen ? <Chat key={receiver}
                                 receiver={receiver} 
                                 openChatCreds={openChatCreds}
                                 setIsChatOpen={setIsChatOpen} 
                           /> : <InitialChatScreen />}
      </ClientChat>
      <Helmet>
            <title>Chats</title>
            <meta name='description' content='Client Chats' />
        </Helmet>
    </ChatsContainer>
  )
}

export default Chats