import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import PropTypes from 'prop-types'
import { IoCopyOutline } from "react-icons/io5";
import { BsCheckSquareFill, BsClock, BsInfoCircle } from 'react-icons/bs'
import { FaImage } from "react-icons/fa";
import { IoMdAlert } from 'react-icons/io'
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase/firebase';

const DateContainer = styled.div`
  text-align: center;
  margin: 10px auto;
`

const DateValue = styled.p`
  display: inline;
  background: #bce8bc;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  margin: 10px auto;
`

const PicturesHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const PicturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`

const Link = styled.a`
  color: blue;
`

const CopiedNote = styled.p`
    color: black;
    position: absolute;
    left: 240px;
`

const timeCorrection = (val) => {
  const time = val.split('T')[1].split('.')[0].slice(0,5)
  // 2hrs behind
  const correctedHrs = Number(time.slice(0,2)) + 2
  const residue = time.slice(2,5)
  const correctedValue = String(correctedHrs).concat(residue)
  return correctedValue
}

function Message({ message }) {
  const { messages } = useSelector(state => state.sendMessage)
  const { author, body, createdAt, type, value } = message
  const [fbUser, loading, error] = useAuthState(auth);
  const { user } = useAuth0();
  const [arr, setArr] = useState([])
  const [copied, setCopied] = useState(false)
  const email = fbUser ? fbUser.email : user ? user.email : null

  const msg = messages.find( msg => msg.body === body)

  useEffect(() => {
    if (body?.includes('images:')) {
        let arr = body.split(',')
        arr.shift()
        setArr(arr)
    }
},[])

useEffect(() => {
  if (copied) {
    setTimeout(() => setCopied(false),1000)
  }
},[copied])

const copyToClipBoard = () => {
  navigator.clipboard.writeText(body)
  setCopied(true)
}

  return (
    <>
      {
        type ? <DateContainer><DateValue>{value}</DateValue></DateContainer> : 
        <div className={author === email ? 'messages__message messages__message-me' : 'messages__message'}>
          { copied ? <CopiedNote>Copied!</CopiedNote>: null }
          {
                 body.includes('images:') ? <PicturesContainer>
                 <PicturesHeader>
                    <FaImage name="image" 
                      size={20} 
                      color='black'
                    />
                    <p style={{ color: 'black' }}>Images</p>
                 </PicturesHeader>
                 {
                     arr.map((link, idx) => {
                        let key = author === user?.email ? user.email: fbUser?.email ? fbUser.email: author
                        let url = `https://tapaservice.s3.us-east-1.amazonaws.com/clients/${encodeURIComponent(key)}/${link}`
                      return    <Link target="_blank" rel="noopener noreferrer" href={url}>{link}</Link>
                     })
                 }
             </PicturesContainer>:<div className='messages__text' style={{...(body.includes('bac_') && { color: 'blue' })}}>
              {body.includes('bac_') ? 'Authorisation Code: '.concat(body): body}
              {body.includes('bac_') ? <IoCopyOutline cursor={'pointer'} onClick={copyToClipBoard}/>: null }
            </div>
            }
            <div className={author === email ? 'messages__meta messages__meta-me messages__text' : 'messages__meta messages__text'}>
            <p className='messages__timestamp messages__text'>
                  { !msg ?  timeCorrection(createdAt) : 
                    msg?.sent ? `${new Date().toISOString().split('T')[1].split('.')[0].slice(0,5)}` : 
                    null
                  }
            </p>
            <span className={author !== email ? 'hide' : ''}>
              {
                msg?.sending ? <BsClock size={10}  /> : msg?.sent ? <BsCheckSquareFill size={10} /> :
                msg?.sendError ? <IoMdAlert size={10} color='red' /> : <BsCheckSquareFill size={10} />
              }
            </span>
            </div>
          </div>
      }
    </>
  )
}

Message.propTypes = {
  
}

export default React.memo(Message);
