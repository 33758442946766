import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';

import { fetchSubscriptions } from '../../Components/subscriptions/services.slice'
import Footer from '../../Components/footer/footer'

const Section = styled.div`
    text-align: center;
    padding: 30px 20px 60px;
    border-bottom: 1px solid black;
`

const SectionNoUnderline = styled(Section)`
    border-bottom: none;
`

const ImagesContainer = styled.div`
    @media (min-width: 750px) {
        display: flex;
        margin-top: 50px;
        justify-content: center;
        gap: 5px;
    }
`

const ImageContainer = styled.div`
    position: relative;
`

const Amount = styled.p`
    position: absolute;
    top: 27%;
    left: 35%;
    color: white;
    font-size: 40px;
    font-weight: bold;
    @media (min-width: 400px) {
        font-size: 10vw;
    }
    @media (min-width: 750px) {
        top: 31%;
        left: 38%;
        font-size: 4vw;
    }
    @media (min-width: 1135px) {
        font-size: 40px;
        left: 35%;
    }
`

const Commission = styled.p`
    position: absolute;
    left: 57%;
    top: 51.8%;
    color: white;
    font-size: 5vw;
    font-weight: bold;
    @media (min-width: 750px) {
        top: 57%;
        left: 57%;
        font-size: 2vw;
    }
    @media (min-width: 835px) {
        top: 56%;
    }
    @media (min-width: 1135px) {
        top: 206px;
        font-size: 22px;
    }
`

const Image = styled.img`
    object-fit: contain;
    margin: 15px auto;
    width: 90%;
    @media (min-width: 750px) {
        width: 90%;
        height: 100%;
        max-width: 340px;
    }
`

const Description = styled.p`
    line-height: 1.5;
    margin: 0 auto 15px;
    font-size: 16px;
    @media (min-width: 750px) {
        margin-bottom: 0;
        width: 80%;
    }
`

const Terms = styled.h2`
    text-decoration: underline;
    cursor: pointer;
`

const Note = styled(Description)`
    @media (min-width: 800px) {
        width: 80%;
    }
`


function Subscriptions() {
    const { subscriptions, subscriptionsError} = useSelector(state => state.subscriptions)
    const dispatch = useDispatch()
    const history = useHistory()

    const options = {
        services: [
            { 
                key: 'silver',
                amount: subscriptions?.services?.plans?.silver?.amount,
                commission: subscriptions?.services?.plans?.silver?.commission  
            },
            { 
                key: 'gold',
                amount: subscriptions?.services?.plans?.gold?.amount,
                commission: subscriptions?.services?.plans?.gold?.commission,
            },
            { 
                key: 'platinum',
                amount: subscriptions?.services?.plans?.platinum?.amount ,
                commission: subscriptions?.services?.plans?.platinum?.commission 
            },
        ],
        towing: [ 
            { 
                key: 'silver',
                amount: subscriptions?.towing?.plans?.silver?.amount ,
                commission: subscriptions?.towing?.plans?.silver?.commission 
            },
            { 
                key: 'gold',
                amount: subscriptions?.towing?.plans?.gold?.amount ,
                commission: subscriptions?.towing?.plans?.gold?.commission 
            },
            { 
                key: 'platinum',
                amount: subscriptions?.towing?.plans?.platinum?.amount,
                commission: subscriptions?.towing?.plans?.platinum?.commission,   
            },
        ]
    }

    const openTnCs = () => history.push('/terms-of-use')

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        dispatch(fetchSubscriptions())
    },[])

    useEffect(() => {
        if (subscriptionsError) {
            window.alert('Error: Failed to fetch subscriptions data. Plese try again later.')
        }
    },[subscriptionsError])

  return (
    <div>
        <article>
        <Section>
        <h1>Subscription Model and Pricing Structure</h1>
        <Description>We offer a comprehensive monthly subscription plan designed to suit the needs of Service Providers in the Towing and Car Servicing Industries. Our flexible, three-tier pricing structure ensures that businesses of varying sizes and capacities can choose the package that best aligns with their needs. Each tier offers a balance between upfront subscription costs and commission rates on booking fees, allowing our partners to maximise their earnings as they scale.</Description>
        </Section>
        <Section>
            <h1>1. Towing Services Subscription Plans</h1>
            <Description>Our Towing partners can choose from 3 distinct Subscription Tiers. Each plan is structured to provide  clear path towards reducing commission fees as the Susbcription rates increase, empowering businesses to retain more revenue as they grow.</Description>
            <ImagesContainer>
                {options.towing.map( el => {
                    let { key, amount, commission } = el
                    let image = `towing-${key}.png`
                    return (
                        <ImageContainer>
                            <Image src={`/${image}`} alt="" />
                            <Amount>{amount ? `R ${amount}`: '...'}</Amount>
                            <Commission>{commission ? `${commission}%`: '...'}</Commission>
                        </ImageContainer>
                    )
                })}
            </ImagesContainer>
        </Section>
        <Section>
            <h1>2. Car Servicing Subscription Plans</h1>
            <Description>For Car Service Garages our Subscription Plans offer the same three-tier structure, allowing garages to align their their subscription with their business size and service volume. As they scale, they can benefit from reduced commission rates, helping them improve profitability.</Description>
            <ImagesContainer>
                {options.services.map( el => {
                    let { key, amount, commission } = el
                    let image = `services-${key}.png`
                    return (
                        <ImageContainer>
                            <Image src={`/${image}`} alt="" />
                            <Amount>{amount ? `R ${amount}`: '...'}</Amount>
                            <Commission>{commission ? `${commission}%`: '...'}</Commission>
                        </ImageContainer>
                    )
                })}
            </ImagesContainer>
        </Section>
        <SectionNoUnderline>
            <Terms onClick={openTnCs}>Terms And Conditions</Terms>
            <Note>This structure offers flexibility and scalability for both towing and car servicing companies, ensuring that businesses of any size can grow with us while managing costs. </Note>
        </SectionNoUnderline>
    </article>
    <Footer />
    <Helmet>
              <title>Subscriptions</title>
              <meta name='description' content='TapAService Service Provider Subscription Models and Pricing.' />
              <link rel="canonical" href="https://www.tapaservice.com/subscriptions" />
            </Helmet>
    </div>
  )
}

export default Subscriptions