import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 0.1px solid grey;
    padding: 10px 0;
`

const Button = styled.p`
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
`

const Logo = styled.img`
    width: 50px;
    height: 30px;
    background-color: red;
`

function Nav() {
  return (
    <Container>
        <Button>News</Button>
        <Button>Politics</Button>
        <Button>GNU 100 Days</Button>
        <Button>Opinion</Button>
        <Button>Sport</Button>
        <Button>TshisaLIVE</Button>
        <Button>Lifestyle</Button>
        <Button>Podcasts</Button>
        <Button>Premium</Button>
        <Button>Partners</Button>
        <Logo />
    </Container>
  )
}

export default Nav