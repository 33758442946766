import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../../redux/axios/axios'

const initialState = {
  loading : false,
  banks: [],
  banksError: ''
}

const banks = createSlice({
  name: 'banks',
  initialState,
  reducers: {
    fetchedBanksRequest(state) {
      state.loading = true
    },
    fetchedBanksSuccess(state, action) {
        state.loading = false
        state.banks = action.payload
    },
    fetchedBanksError(state, action) {
      state.loading = false
      state.banksError = action.payload
      state.banks = []
    },
    fetchedBanksReset(state) {
      state.loading = false
      state.banksError = ''
      state.banks = []
    },
  }
})

export const { fetchedBanksRequest, fetchedBanksSuccess, fetchedBanksError, fetchedBanksReset } = banks.actions

export const fetchBanks = () => async (dispatch) => {
  dispatch(fetchedBanksRequest())
  try {
      const { data: { data }} = await customAxios.get('/paystack/banks-validation-list');
      dispatch(fetchedBanksSuccess(data))
  } catch (error) {
      dispatch(fetchedBanksError(error.message))
  }      
}

export const banksListReducer = banks.reducer
