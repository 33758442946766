import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import {MdOutlinePayments} from 'react-icons/md'
import {GiRockingChair} from 'react-icons/gi'
import {RiServiceLine} from 'react-icons/ri'
import { BsInfoCircle } from 'react-icons/bs'
import { FaTrash } from "react-icons/fa";

import { InformativeFeature } from '../../../../Components/feature/feature'
import { InputFieldReq, TextAreaFieldReq } from '../../../../Components/field/field'
import { ListItemsWithAutoCompletion, ListItems } from '../../../../Components/list/ListItems'
import { FormButton } from '../../../../Components/button/Button'
import { useStepper } from '../../../../Components/stepper/hook'
import { numberValidator } from '../../../../utils/validators'

export const Tip = styled.p`
  text-align: right;
  color: blue;
`

const ButtonContainer = styled.div`
    padding-right: 35px;
    padding-top: 50px;
    text-align: right;
`

export const AddressLabelContainer = styled.div`
display: flex;
    gap: 5px;
`

export const Close = styled.div`
    position: absolute;
    right: 200px;
    z-index: 5;
    cursor: pointer;
`

const features = [
    { icon: <MdOutlinePayments />,text:'No extra fees, pay as you earn.',heading:'Pay as you go' },
    { icon:<GiRockingChair />,text:'We do all the heavy lifting for you',heading:'Sit back and relax'},
    { icon:<RiServiceLine />,text:"The easiest way to market your services and products",heading:'We make it easy'}
  ]

function Form({ formValues, formMethods, formErrors, companyID, handleCompanyIDChange, lengthValidation }) {
    const [error, setError] = useState(false)
    const [logoSizeError, setLogoSizeError] = useState(null)
    const {incrementCurrentStep} = useStepper();
    const [errors, setErrors] = useState({ })
   const { selectedImage, autocomplete, formData, services, phoneNo, address } = formValues
   const { companyName, statement, rate, liabilityCover } = formData
   const isImageUndersizeRef = useRef()

  const handleItemChange = async (e,idx) => formMethods.handleItemChange(e,idx)
  const handleItemRemove = (e,idx) => formMethods.handleItemRemove(e,idx) 

  const next = () => {
    if (companyName && !numberValidator(rate.flatBed) && !numberValidator(rate.wheelLift) && 
        !numberValidator(rate.hookChain) && statement && services[0].length && !numberValidator(liabilityCover) && !isImageUndersizeRef.current &&
        phoneNo[0].length && address[0].length && !Object.keys(formErrors).length && !Object.keys(lengthValidation).length) {
        incrementCurrentStep()
    } else {
        if (Object.keys(formErrors).length || Object.keys(lengthValidation).length || Object.keys(errors).length) {
            setError('Input Validation Error(s)')
        }else if (isImageUndersizeRef.current) {
            setError('Logo: One or more dimensions are less than 225')
        } else {
            setError('Missing Fields!')
        }
    }
}

const handleOnChange = (e) => {
    const {name, value } = e.target
    if (name === 'liabilityCover') {
        formMethods.setFormData({...formData, [name]: value })
    } else {
        formMethods.setFormData({...formData, rate: {...rate, [name]: value }})
    }
    if (numberValidator(value)) {
        setErrors({...errors, [name]: 'Only Numbers are allowed'})
    } else {
        delete errors[name]
    }  
}

const handleImageLoad = (e) => {
    const { naturalHeight, naturalWidth } = e.target;;
      if (naturalHeight < 225 || naturalWidth < 225) {
        isImageUndersizeRef.current = true
        alert('Logo: One or more dimensions are less than 225')
      } else {
        isImageUndersizeRef.current = false
      }
  }

const removeImage = () => {
    isImageUndersizeRef.current = false
    formMethods.removeImage()
}

  return (
    <section className='ra'  >
        <div  className='ra__container'>
        <article className='services__root'>
            <div className='services__headings'>
                <h1 className='form__h1'>Create your RoadSide Assist Service Profile</h1>
                <p className='form__p'>Get more discovery on your clients' radar</p>
            </div>
            <form className='form rdsd-form' >
                <p className='form__h'>Fill in the form below</p>
                <div className="form__profile" style={{ position: 'relative'}}>
                    <h4 style={{ fontWeight: 600 }}>Logo</h4>
                    <p>(500kB max size; 225x225 minimum dimensions)</p>
                    <p style={{ fontSize: 'x-small' }}>Click image below to Edit</p>
                    <p style={{ fontSize: 'x-small' }}>*Can Be Uploaded Later</p>
                    <div className='form__image'>
                    { (selectedImage || formErrors['logo']) ? <Close onClick={removeImage}>
                        <FaTrash  size={20} />
                    </Close>: null }
                    <label htmlFor="image" style={{ cursor:'pointer' }}>
                    <img className= 'form__img' 
                         src={selectedImage ? (URL.createObjectURL(selectedImage)): '/add.png' } 
                         alt="logo"
                         style={{ objectFit: 'center' }}
                         onLoad={handleImageLoad}
                    />
                    </label>
                    <p style={{ color: 'red' }}>{formErrors['logo'] || logoSizeError}</p>
                    </div>
                    <input style={{display:'none'}} type="file" id='image' name="image" className='form__file'  onChange={formMethods.handleImageChange} required/>
                </div>
                <div className='form__fields' >
                <InputFieldReq label='Company Name' value={companyName} name='companyName' placeholder='Company Name not exceeding 25 characters' onChange={formMethods.handleOnChange} />
                <Tip>Company Name can not be changed later</Tip>
                {<p className="form__error">{formErrors['companyName']}</p>}
                { lengthValidation.companyName ? <p className="form__error">{lengthValidation['companyName']}</p>: null }
                <InputFieldReq label='Company ID' 
                                tooltip={true} 
                                toolTipText="This is your Public Key that will be given to your registered drivers. They will use it as part of their Login credentials" 
                                value={companyID} 
                                name='companyID' 
                                placeholder='CompanyID' 
                                onChange={handleCompanyIDChange} 
                    />
                <Tip>Company ID is case sensitive and can not be changed later</Tip>
                <div className='form__item' >
                    <label htmlFor="address">Statement*:</label>
                    <TextAreaFieldReq name='statement' className='form__input' rows='2' value={statement} onChange={formMethods.handleOnChange} placeholder='Mission statement not more than 100 characters' />    
                </div>
                {<p className="form__error">{formErrors['statement']}</p>}
                {<p className="form__error">{lengthValidation['statement']}</p>}
                <div className='form__item' >
                <label htmlFor="address">Tel No.*:</label>
                    <div className="form__address" >
                    <ListItems data={phoneNo} placeholder={'011XXXXXXXX'} handleItemAdd={formMethods.handleItemAdd} name={'phone'}
                                handleItemRemove={handleItemRemove} handleItemChange={handleItemChange} rows={1} />
                    </div>
                </div>
                {<p className="form__error">{formErrors['phone']}</p>}
                <div className='services__block'>
                </div>
                <div className='form__item' >
                    <AddressLabelContainer>
                    <label htmlFor="address">Address*:</label>
                    <div className="form__tooltip-icon" title='Select the Address suggested from the autocomplete dropdown.  Entering your own address will result in your profile being excluded from location search results. If your address doesnt show up trying entering the Street without the house number.'>
                            <BsInfoCircle size={20} color='blue' />
                        </div>
                    </AddressLabelContainer>
                    <div className="form__address" >
                    <ListItemsWithAutoCompletion data={address} placeholder={'Please enter your address'} handleItemAdd={formMethods.handleItemAdd} name={'address'}
                                handleItemRemove={handleItemRemove} handleItemChange={handleItemChange} autocomplete={autocomplete} />
                    <Tip>Select Address from the dropdown. Never enter your own address.</Tip>
                </div>
                </div>
                <div className='services__block'>
                    <p className='form__h'>Services</p>
                    <p style={{paddingBottom:'15px'}}>Give a brief description of what you offer</p>
                    <ListItems data={services} placeholder={'We do x,y,z'} handleItemAdd={formMethods.handleItemAdd} name={'services'}
                                handleItemRemove={handleItemRemove} handleItemChange={handleItemChange} />
                </div>
                <div className="services__block">
                <p className='form__h'>Towing Rates (ZAR/km)</p>
                <InputFieldReq label='FLATBED' 
                                value={rate.flatBed} 
                                name='flatBed' 
                                placeholder='XX' 
                                onChange={handleOnChange}
                />
                {<p className="form__error">{errors['flatBed']}</p>}
                <InputFieldReq label='HOOK & CHAIN' 
                                value={rate.hookChain} 
                                name='hookChain' 
                                placeholder='XX' 
                                onChange={handleOnChange}
                />
                {<p className="form__error">{errors['hookChain']}</p>}
                <InputFieldReq label='WHEEL LIFT' 
                                value={rate.wheelLift} 
                                name='wheelLift' 
                                placeholder='XX' 
                                onChange={handleOnChange}
                />
                {<p className="form__error">{errors['wheelLift']}</p>}
                </div>
                <div className="services__block">
                <p className='form__h'>3rd Party Liability Cover</p>
                <InputFieldReq label='Max Liability Cover (ZAR)' 
                                value={liabilityCover} 
                                name='liabilityCover' 
                                placeholder='e.g 500000' 
                                onChange={handleOnChange} 
                                tooltip={true} 
                                toolTipText="The max payout to 3rd Parties in case of accident during towing operations"
                />
                {<p className="form__error">{errors['liabilityCover']}</p>}
                </div>
                </div>
            </form>
        </article>
        </div>
        <ButtonContainer>
            <FormButton
            background='#76C3D4' 
            color='white' 
            onClick={next}>
                Next
            </FormButton>
            { error ? <p style={{ color: 'red'}}>{error}</p>: null }
        </ButtonContainer>
        <article className="info">
            <div className="info__features">
            {features.map(({icon,text,heading},idx)=>{
                return <InformativeFeature key={idx} icon={icon} text={text} heading={heading} />
            })}
            </div>
        </article>
    </section>
  )
}

export default Form