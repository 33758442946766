import React, { useEffect, useState, useRef, useContext } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom'
import DateTimePicker from 'react-datetime-picker'
import { useIdToken } from 'react-firebase-hooks/auth';
import { Helmet } from 'react-helmet-async';

import useGetFormValuesAndMethods from '../../utils/form-hook'
import { Button } from '../../Components/button/Button';
import { Uploading } from '../../Components/loader/loader';
import { auth } from '../../firebase/firebase';
import { fetchBookingDetail } from '../../Components/booking/booking.detail.slice';
import { useCreateBookingMutation } from '../../Components/api/api.slice';
import customAxios from '../../redux/axios/axios'
import { FirebaseContext } from '../../firebase/firebase.context';

const Error = styled.p`
  color: red;
  text-align: right;
`

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px auto;
`

const SuccessTxt = styled.p`
  color: green;
`

const ExistingBooking = styled.p`
display: flex;
align-items: center;
justify-content: center;
height: 90vh;
color: red;
`

const SubHeading = styled.h4`
  font-size: 16px;
    font-weight: 500;
`

const Heading = styled.h2`
font-weight: 500;
font-size: 18px;
font-size: 20px;`

const Success = () => {
  const history = useHistory()

  return <SuccessContainer>
            <SuccessTxt>Booking Created Successfully</SuccessTxt>
            <Button background='green'
                      borderRadius='5px'
                      color='white'
                      onClick={() => history.push('/booking')}
              >
                View Booking
            </Button>
          </SuccessContainer>
}

const FormTextInput = ({ formErrors, handleOnChange, placeholder, label, name, autocomplete, value }) => {
  
  return (
    <div>
      <div className="booking__form-item">
            <label htmlFor={name}>{label}*:</label>
            <input type="text" 
                    name={name} 
                    id={name} 
                    value={value}
                    list={name}
                    placeholder={placeholder}
                    onChange={handleOnChange} 
                    className='booking__input'
            />
            {
              autocomplete ? <datalist id={name}>
                                  {
                                    [1,2,3,4,5].map(item => {
                                      return (
                                        <option style={{ width:'90%' }} key={Math.random()} value={item}>{item}</option>
                                      )
                                    })
                                  }
                            </datalist> : null
            }
          </div>
          {formErrors[name] ? <Error >{formErrors[name]}</Error> : null }
    </div>
    
  )
}

function CreateBooking() {
  const { booking } = useSelector(state => state.bookingDetail)
  const [createBooking, { isSuccess, isLoading, isError, error: createError }] = useCreateBookingMutation()
  const [user, loading, error] = useIdToken(auth);
  const { firebaseAuth } = useContext(FirebaseContext)
  const [formValues,formMethods,formErrors] = useGetFormValuesAndMethods()
  const [date, setDate] = useState(new Date(Date.now()));
  const [isAuthorised, setIsAuthorised] = useState({ success: false, error: '', verifying: false })
  const [authorisationCode, setAuthorisationCode] = useState('')
  const { state: { address, companyName, email: companyEmail } } = useLocation()
  const [checked, setChecked] = useState({ branch: address[0].address, tier: null })
  const [btnDisable, setBtnDisable] = useState(false)
  const [validationErrors, setValidationErrors] = useState(null)
  const { autocomplete, formData } = formValues
  const { handleOnChange } = formMethods
  const { make, model, modelYear, tel, sAddress, vinNumber, mileage, vehicleRegistration } = formData
  const dispatch = useDispatch()
  const history = useHistory()
  const APIToken = useRef(null)

  useEffect(() => {
    if (isSuccess) {
      dispatch(fetchBookingDetail(user.email, APIToken.current ))
      history.push('/booking')
    }
  },[isSuccess])

  useEffect(() => {
    if (isError) {
      setBtnDisable(false)
    }
  },[isError])

  const submitBooking = async(e) => {
    e.preventDefault()
      if (Object.keys(formErrors).length) {
        return setValidationErrors('Input Validation Error(s)')
    }
    try {
      const token = await user.getIdToken()
      APIToken.current = token
      const postData = {
        ...checked,
        clientEmail: user.email,
        companyEmail,
        companyName,
        make,
        model,
        mileage,
        modelYear,
        vehicleRegistration: vehicleRegistration.toUpperCase(),
        fullName: firebaseAuth?.user?.displayName,
        vinNumber,
        dateTime: date.toLocaleString(), 
        datetime: Date.now(),
        homeAddress: sAddress,
        phone: tel,
        searchField: firebaseAuth?.user?.displayName.toLowerCase()
      }
      // console.log('post data', postData)
      for (const key in postData) {
          if (!postData[key] ) {
            return setValidationErrors('Missing Fields All fields should have non empty values')
          }
      }
      if (date.getTime() < new Date().getTime()) {
        return setValidationErrors('Incorrect DateTime. Appointment date should be a future date')
      }
      createBooking({ ...postData, token })
      setBtnDisable(true)
    } catch (error) {
      console.log('err', error)
      setValidationErrors('Error Creating Booking. Try again.')
    }
  }

  const verifyAuthorisationToken = async(e) => {
    try {
      e.preventDefault()
      setIsAuthorised({ verifying: true, success: false, error: '' })
      const token = await user.getIdToken()
      await customAxios.post('/booking/verify-authorisation-code', 
        { clientEmail: user.email, authorisationCode }, 
        { headers: { 'Authorization': `Bearer ${token}` } });
      setIsAuthorised({ success: true, error: '', verifying: false })
    } catch (error) {
      setIsAuthorised({ verifying: false, success: false, error: 'Invalid Authorisation Code. Request Valid Code from your Service Provider' })
    }
  }

  return (
    <div>
      {
        booking ? <ExistingBooking>You already have a booking</ExistingBooking>: (
      <section className="booking__create">
        <div className="booking__header">
          <Heading>Create your Booking</Heading>
          <div className="booking__image">
          <img src="/create-booking.jpg" className="booking__img" alt="booking__img" />
        </div>
        </div>
        <form className="booking__form">
          <section className="booking__block">
            <SubHeading>Personal Details</SubHeading>
            <FormTextInput name='tel'
                          placeholder='0XXXXXXXXX'
                          label='Mobile'
                          value={tel}
                          handleOnChange={handleOnChange}
                          formErrors={formErrors} 
            />
            <FormTextInput name='sAddress'
                          label='Address'
                          value={sAddress}
                          placeholder='123 Street Address, Suburb'
                          handleOnChange={handleOnChange}
                          formErrors={formErrors}
                          autocomplete={autocomplete} 
            />
          </section>
          <section className="booking__block">
            <SubHeading>Vehicle Details</SubHeading>
            <FormTextInput name='make'
                          placeholder='Nissan'
                          label='Make'
                          value={make}
                          handleOnChange={handleOnChange}
                          formErrors={formErrors} 
            />
            <FormTextInput name='model'
                          placeholder='NP 300'
                          label='Model'
                          value={model}
                          handleOnChange={handleOnChange}
                          formErrors={formErrors} 
            />
            <FormTextInput name='vehicleRegistration'
                          placeholder='XY12ABGP'
                          label='Reg. No'
                          value={vehicleRegistration}
                          handleOnChange={handleOnChange}
                          formErrors={formErrors} 
            />
            <FormTextInput name='vinNumber'
                          placeholder='XXXXXXX'
                          label='VIN'
                          value={vinNumber}
                          handleOnChange={handleOnChange}
                          formErrors={formErrors} 
            />
            <FormTextInput name='mileage'
                          placeholder='00000'
                          label='Mileage'
                          value={mileage}
                          handleOnChange={handleOnChange}
                          formErrors={formErrors} 
            />
            <FormTextInput name='modelYear'
                          placeholder='2008'
                          label='Year'
                          value={modelYear}
                          handleOnChange={handleOnChange}
                          formErrors={formErrors} 
            />
            <FormTextInput name='error'
                          placeholder='xxxx'
                          label='Authorisation Code'
                          value={authorisationCode}
                          handleOnChange={e => setAuthorisationCode(e.target.value)}
                          formErrors={isAuthorised} 
            />
            <div style={{ textAlign: 'end' }}>
              <Button background='blue'
                        borderRadius='5px'
                        color='white'
                        disabled={isAuthorised.success || !authorisationCode || isAuthorised.verifying}
                        onClick={verifyAuthorisationToken}>
                          Verify Authorisation Code
              </Button>
              { isAuthorised.verifying ? <p style={{ color: 'blue' }}>Verifying..</p>: 
                isAuthorised.success ? <p style={{ color: 'green' }}>Valid Code</p>: null }
            </div>
            <div className='booking__selection'>
              <p>Select Service Tier</p>
            {['major', 'minor'].map((tier,idx) => {
                return <div key={idx} className='booking__radio-option'>
                        <input type="radio" 
                               value={tier}
                               name='service-tier'
                               id={tier}
                               onClick={() => setChecked({...checked, tier})}  
                        />
                        <label htmlFor={tier}>{`${tier[0].toUpperCase()}${tier.slice(1)}`}</label>
                      </div>
            })}
            </div>
            <div className='booking__selection'>
              <p>Select Branch</p>
              <select name="branch" 
                      className='booking__select' 
                      onChange={(e) => console.log(e.target.value)}>
                {
                  address.map(({ address },idx) => {
                    return <option key={idx} value={address} >{address}</option>
                  })
                }
              </select>
            </div>
            <div className="booking__selection">
              <p>Set Appointment</p>
              <div style={{ marginTop: '8px'}}>
                <DateTimePicker onChange={(val) => setDate(val)} value={date} />
              </div>
            </div>
          </section>
          <div className="booking__btn-container">
            <Button background='green'
                      borderRadius='5px'
                      color='white'
                      disabled={btnDisable || !isAuthorised.success}
                      onClick={submitBooking}
              >
                Create Booking
            </Button>
          </div>
          { isLoading ? <Uploading text="creating booking" color={'black'}/> : 
            isError ? <Error>Error creating Booking. Try again.</Error> :
            validationErrors ? <Error >{validationErrors}</Error> :
            null }
        </form>
      </section>
       )
      }
      <Helmet>
                      <title>Create Booking</title>
                      <meta name='description' content='Create Booking' />
                  </Helmet>
    </div>
  )
}

export default CreateBooking