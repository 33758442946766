import React from "react";

const PageNotFound = () => {
    return (
        <div>
            <p>404</p>
            <p>Page not Found</p>
        </div>
    )
}

export default PageNotFound