import React,{useState,useEffect,useMemo, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { createProfile } from '../../../Components/service/service.create.slice';
import Stepper from '../../../Components/stepper/stepper';
import { useStepper } from '../../../Components/stepper/hook';
import { uploadFiles } from '../../../Components/fileUploads/upload.slice';
import FileUploads from '../../../Components/fileUploads/FileUploads';
import { fetchPreUploadData } from '../../../Components/fileUploads/preupload.slice';
import useGetFormValuesAndMethods from '../../../utils/form-hook';
import CreateProfileSuccess from './CreateProfileSuccess';
import Form from './forms/roadside-assist';
import { Loading } from '../../../Components/loader/loader';
import Consent from './Consent';

export const LocationAlertContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    align-items: center;
    justify-content: center;
`

export const LocationAlertText = styled.p`
    font-size: 16px;
`

const RoadsideAssist = () => {
  const {data,fetchError,fetched} = useSelector(state => state.preUpload)
  const { uploaded, uploadFilesError} = useSelector(state => state.uploadFiles)
  const { createProfileError} = useSelector(state => state.createProfile)
  const { metadata: { user_id } } = useSelector(state => state.metadata)
  const { location, loading } = useSelector(state => state.location)
  const { currentStep, setSteps } = useStepper()
  const { getAccessTokenSilently, user: { email }} = useAuth0()
  const [btnDisable, setBtnDisable] = useState(true)
  const dispatch = useDispatch()
  const [formValues,formMethods,formErrors, lengthValidation] = useGetFormValuesAndMethods()
  const { selectedImage, images, video, formData, services, phoneNo,  address } = formValues
  const { companyName, statement, rate, liabilityCover} = formData
  const [companyID,setCompanyID] = useState('')
  const { state } = useLocation()
  const history = useHistory()
  let accessToken = useRef()

  useEffect(()=> {
    if (fetched) {
      const form = new FormData();
      const arr = [selectedImage,video,...images]
      const files = arr.filter(Boolean)
      for (let key in data) {
        if (key !== 'upload_url' && key !== 'key') form.append(key, data[key])
      }
      dispatch(uploadFiles(form, files, data.upload_url, data.key))      
    }
  },[fetched])

  useEffect(() => {
    
    if (uploaded) {
      const imagesS3links = []
      for(let img of images){
        imagesS3links.push(`${data.key}/${img.name}`)
      }
      // set 3 months expiration 
      let today = new Date();
      const exp = today.setMonth(today.getMonth() + 3)
      
      const postData = {
        logo: selectedImage ? `${data.key}/${selectedImage.name}` : null,
        video: video ? `${data.key}/${video.name}`: null,
        email,
        reviewsCount: 0,
        reviewsTotalScore: 0.0,
        rating: 0,
        ratingFilter: 0,
        companyProfile: 1,
        imagesS3links,
        companyName,
        phoneNo,
        statement,
        address,
        rate: { flatBed: Number(rate.flatBed), wheelLift: Number(rate.wheelLift), hookChain: Number(rate.hookChain) },
        liabilityCover: Number(liabilityCover),
        services,
        companyID,
        verified: 'false',
        type: 'roadside_assistance',
        locationSearchField: address[0].split(',')[1].trim().toLowerCase(),
        subscriptionStatus: 'active',
        subscriptionStatusFilter: 'active',
        profileStatus: 'inactive',
        subscriptionTier: state?.selectedPlan.plan,
        ...(state?.selectedPlan?.plan === 'Free' && { trialExpirationTimeStamp: exp }),
        currentSubscriptionTier: '',
        towersCount: 0,
        completedLifetimeRequests: 0
      }
      dispatch(createProfile(postData,'Roadside Assist',user_id,accessToken.current,location?.textualInfo?.country)); 
    }
  },[uploaded])


  useEffect(()=>{
    (createProfileError || uploadFilesError || fetchError) && setBtnDisable(false)
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    setBtnDisable(true)
    accessToken.current = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE
    })
    if(!video && !images.length && !selectedImage){
      // set 3 months expiration 
      let today = new Date();
      const exp = today.setMonth(today.getMonth() + 3)

      const postData = {
        logo: null,
        video: null,
        email,
        reviewsCount: 0,
        reviewsTotalScore: 0.0,
        rating: 0,
        ratingFilter: 0,
        companyProfile: 1,
        imagesS3links: [],
        companyName,
        phoneNo,
        statement,
        address,
        rate: { flatBed: Number(rate.flatBed), wheelLift: Number(rate.wheelLift), hookChain: Number(rate.hookChain) },
        liabilityCover: Number(liabilityCover),
        services,
        companyID,
        verified: 'false',
        type: 'roadside_assistance',
        locationSearchField: address.toString().toLowerCase(),
        subscriptionStatus: 'active',
        subscriptionStatusFilter: 'active',
        profileStatus: 'inactive',
        subscriptionTier: state?.selectedPlan.plan,
        ...(state?.selectedPlan?.plan === 'Free' && { trialExpirationTimeStamp: exp }),
        currentSubscriptionTier: '',
        towersCount: 0,
        completedLifetimeRequests: 0
      }
      dispatch(createProfile(postData,'Roadside Assist',user_id,accessToken.current,location?.textualInfo?.country)); 
    } else {
      dispatch(fetchPreUploadData(accessToken.current, email))
    }
 }

 const handleCompanyIDChange = (e) => {
  const {value} = e.target
  setCompanyID(value)
  }

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
  
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  const steps = useMemo(() => {
    return [
    { name: "T's & C's", index: 0 },
    { name: 'Company Details', index: 1 },
    { name: 'Assets Uploads', index: 2 },
    { name: 'Success', index: 3 }
  ]},[])

  useEffect(() => {
    setSteps(steps)
  },[])

  useEffect(() => {
    if (!state?.profileType) {
      history.push('/services/create')
    }
  },[])

  return (
        <div>
          {
          loading ? <Loading loading={loading} label={'Fetching Location..'} />:
          !Object.keys(location).length ? <LocationAlertContainer><LocationAlertText>This page needs location access to function properly. To be able to create a Profile you need to allow location access.</LocationAlertText></LocationAlertContainer>:
          <section >
          <Stepper steps={steps} currentStep={currentStep} />
          <section>
            
            {
              currentStep === 0 ? <Consent />:
              currentStep === 1 ? <Form formValues={formValues} 
                                        formMethods={formMethods} 
                                        formErrors={formErrors} 
                                        lengthValidation={lengthValidation}
                                        companyID={companyID}
                                        handleCompanyIDChange={handleCompanyIDChange}
                                    /> :
              currentStep === 2 ? <FileUploads  handleSubmit={handleSubmit} 
                                                btnDisable={btnDisable} 
                                                heading={'Add Images (workshops, garages etc)'} 
                                                data-test='file-uploads' 
                                                setBtnDisable={setBtnDisable} 
                                                selectedImage={selectedImage}
                                                video={video}
                                                images={images}
                                                formMethods={formMethods}
                                            /> :
              currentStep === 3 ? <CreateProfileSuccess type='roadside_assistance' /> : null
            }
          </section>
        </section>}
        </div>
)}

export default RoadsideAssist
