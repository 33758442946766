import React from 'react'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'

import App from './App'
import store from './redux/store'

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Provider store={store} >
    <HelmetProvider >
      <React.StrictMode>
        <App/>
      </React.StrictMode>
    </HelmetProvider>
  </Provider>
);

