import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../../redux/axios/axios'

const initialState = {
  creating : false,
  transferRecipient: null,
  transferRecipientError: ''
}

const transferRecipient = createSlice({
  name: 'transferRecipient',
  initialState,
  reducers: {
    fetchedTransferRecipientRequest(state) {
      state.creating = true
    },
    fetchedTransferRecipientSuccess(state, action) {
        state.creating = false
        state.transferRecipient = action.payload
    },
    fetchedTransferRecipientError(state, action) {
      state.creating = false
      state.transferRecipientError = action.payload
    },
  }
})

export const { fetchedTransferRecipientRequest, fetchedTransferRecipientSuccess, fetchedTransferRecipientError } = transferRecipient.actions

export const createTransferRecipient = (postData, token) => async (dispatch) => {
  dispatch(fetchedTransferRecipientRequest())
  try {
      const { data } = await customAxios.post('/paystack/create-transfer-recipient',{ ...postData },{
                                                                             headers: {
                                                                                 Authorization: `Bearer ${token}`
                                                                              }
                                                                          })
      dispatch(fetchedTransferRecipientSuccess(data))
  } catch(err)  {
      console.log(err);
      dispatch(fetchedTransferRecipientError(err.message))
  }      
}



export default transferRecipient.reducer
