import React, { useState , useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'query-string'
import StarRatings from 'react-star-ratings';
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { MdOutlineShoppingCartCheckout, MdLogin } from "react-icons/md";
import { useIdToken } from 'react-firebase-hooks/auth'
import { usePaystackPayment } from 'react-paystack'
import short from 'short-uuid';
import {v4 as uuidv4} from 'uuid';
import { Helmet } from 'react-helmet-async';

import { Button } from '../../Components/button/Button'
import customAxios from '../../redux/axios/axios'
import BookingDetailSkeleton from '../../Components/skeletons/booking-detail'
import { updateBooking } from '../../Components/booking/booking.update.slice'
import { auth } from '../../firebase/firebase';
import { fetchBookingDetail } from '../../Components/booking/booking.detail.slice'
import { InteractiveModal } from '../../Components/modal/Modal'
import { FormButton } from '../../Components/button/Button'
import { createReview } from '../../Components/review/reviews.slice'
import { Uploading } from '../../Components/loader/loader'
import { Highlighter } from '../../Components/imageCardWithText/imageCardWithText'
import { FirebaseContext } from '../../firebase/firebase.context'

export const Booking = styled.div`
margin-bottom: 40px;
@media (min-width: 450px) {
    height: 90vh;
    display: flex;
    flex-direction: column;
    
    align-items: center;
  }
`

export const Heading = styled.h1`
    margin-top: 30px;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
`

const Wrapper = styled.div`
    
`

export const BookingContainer = styled.div`
    padding: 0 10px;
    @media (min-width: 450px) {
        padding: 20px;
        background: #f9f9f9;
        width: 80%;
        border-radius: 10px;
        margin: 15px auto;
      };
    @media (min-width: 650px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
`

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
`

export const DetailItem = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 10px;
    @media (min-width: 450px) {
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }
`

export const PaymentOptions = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    margin: 10px;
`

export const ActionsContainer = styled.div`
    display: flex;
    margin: 6px auto 50px;
    justify-content: flex-end;
    padding: 10px;
`

export const Error = styled.p`
      color: red;
      text-align: center;
`

const Section = styled.section`
    border-bottom: 1px solid;
    padding-bottom: 20px;
`

const Subtitle = styled.p`
      font-size: 16px;
`

const Field = styled.p`
      
`

const CarSummary = styled.p`
    background: #b2e4f9;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-weight: 500;
`

const Block = styled.p`
color: white;
`

const Success = styled.p`
      color: green;
      text-align: center;
`

const BtnContainer = styled.div`
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3px;
`

const ActionButton = styled.div`
    display: flex;
    align-items: center;
    background-color: #009dfe;
    padding-left: 8px;
    border-radius: 20px
`

const NoBookingFound = () => {

    return (
        <Container>You do not have a booking.</Container>
    )
}

function BookingDetail() {
    const { loading: updating, isBookingUpdated, bookingUpdateError } = useSelector(state => state.bookingUpdate)
    const { booking, loading } = useSelector(state => state.bookingDetail)
    const { creating, created, createReviewError } = useSelector(state => state.createReview)
    const [verifyToken, setVerifyToken] = useState({ verifying: false, verified: false, verificationError: '' })
    const [isBtnDisabled, setIsBtnDisabled] = useState(false)
    const [isRevBtnDisabled, setIsRevBtnDisabled] = useState(false)
    const [checked, setChecked] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isPromptOpen, setIsPromptOpen] = useState(false)
    const [transaction, setTransaction] = useState({ success: false, cancel: false })
    const [showAlert, setShowAlert] = useState(false)
    const [rating, setRating] = useState(1)
    const [review, setReview] = useState('')
    const [missingReview, setMissingReview] = useState(false)
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const dispatch = useDispatch()
    const { search } = useLocation()
    const history = useHistory()
    const [user ] = useIdToken(auth);
    const { firebaseAuth } = useContext(FirebaseContext)
    const { token, amount, transactionReference, bankReference, paymentOption, clientName, companyID } = queryString.parse(search)

    function getWindowDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height
      };
    }

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const config = {
        reference: transactionReference,
        email: user.email,
        amount: String(Number(amount)*100),
        publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
        currency: 'ZAR'
    };

    const initializePayment = usePaystackPayment(config);

    const onSuccess = async(reference) => {
        try {
            setTransaction({ success: true, cancel: false })
            const accessToken = await user.getIdToken()
            const translator = short();
            let bookingCheckoutID = translator.fromUUID(uuidv4())
            await customAxios.post('/booking/checkout-notification',{ 
                clientEmail: user.email,
                companyName: booking.companyName,
                branch: booking.branch,
                updateBookingAndPayment: true, 
                companyEmail: booking.companyEmail,
                amount, 
                transactionReference,
                bookingCheckoutID
            },{ headers: { 'Authorization': `Bearer ${accessToken}` }})
            dispatch(fetchBookingDetail(user.email,accessToken))
            history.push('/booking/checkout-success')
        } catch (error) {
            console.log('ref error',error)
        }
      };
    
      const onClose = () => {
        setIsBtnDisabled(false)
      }
    
    const beginTokenVerification = async() => {
        setVerifyToken({...verifyToken, verifying: true })
        try {
            await customAxios.post('/verify-token',{ token })
            setVerifyToken({...verifyToken, verifying: false, verified: true })
        } catch (error) {
            setVerifyToken({...verifyToken, verifying: false, verified: false, verificationError: 'Could not verify token' })
        }
    }

    useEffect(() => {
        const getBooking = async() => {
            try {
                const token = await user.getIdToken()
                dispatch(fetchBookingDetail(user.email,token))
            } catch (error) {
                console.log(error);
            }
        }
        getBooking()
    },[])

    useEffect(() => {
        if (created) {
            setTimeout(() => {
                setIsModalOpen(false)
                checkout()
            },[1000])
        }

        if (isBookingUpdated && booking.state === 'COMPLETED') {
            history.push('/booking/checkout-success')
        }

        if (bookingUpdateError) {
            setIsBtnDisabled(false)
        }
        if (createReviewError) {
            setIsRevBtnDisabled(false)
        }
    },[created, isBookingUpdated, bookingUpdateError, createReviewError])

    useEffect(() => {
        const tokenVerification = async() => {
            if (booking && token) {
                await beginTokenVerification()
            }
        }
        tokenVerification()
    },[booking])

    useEffect(() => {
        if (isModalOpen && windowDimensions.width > 650) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        } else if (!isModalOpen && checked) {
            setIsBtnDisabled(false)
        }
    },[isModalOpen])

    const ratingCompleted = (val) => {
        setRating(val)
      }
  
    const handleOnChange = (e) => {
      const { value } = e.target
      setReview(value)
    }
  
    const submitReview = () => {
        if (!review) {
            setMissingReview(true)
            return
        }
        const postData = { companyEmail: booking.companyEmail, consumerEmail: user.email, rating, review, 
            consumerName: firebaseAuth?.user?.displayName, type: 'vehicleMaintenance', datetime: Date.now() }
        dispatch(createReview(postData))
        setIsRevBtnDisabled(true)
    }

    const checkout = async() => {
        if (paymentOption === 'CARD PAYMENT') {
            if (checked === 'ozow') {
                history.push(`/pay?amount=${amount}&bankReference=${bankReference}&transactionReference=${transactionReference}&name=${clientName}&token=${token}`,
                    {
                        companyName: booking.companyName, 
                        companyEmail: booking.companyEmail, 
                        branch: booking.branch, 
                        clientEmail: user.email,
                        vehicleRegistration: booking.vehicleRegistration,
                        paymentOption
                    })
            } else {
                initializePayment({ onSuccess, onClose })
            } 
        } else if (paymentOption === 'CASH PAYMENT') {
            try {
                const token = await user.getIdToken()
                const translator = short();
                let bookingCheckoutID = translator.fromUUID(uuidv4())
                dispatch(updateBooking(booking.companyEmail, 
                    user.email,
                    'COLLECTED',
                    booking.companyName,
                    booking.branch,
                    amount,
                    companyID,
                    paymentOption,
                    token,
                    bookingCheckoutID,))
            } catch (error) {
                console.log(error);
            }    
        }
    }

    const handleBookingUpdate = async() => {
        try {
            setIsPromptOpen(false)
            setIsBtnDisabled(true)
            if (booking.state === 'PENDING') {
                const token = await user.getIdToken()
                dispatch(updateBooking(booking.companyEmail, 
                    user.email,
                    'INPROGRESS',
                    booking.companyName,
                    booking.branch,
                    null,
                    null,
                    null,
                    token))
            } else if (booking.state === 'COMPLETED') {
                setIsModalOpen(true)
            }
        } catch (error) {
            setIsBtnDisabled(false)
        }
    }

    const handleCancelBooking = async() => {
        try {
            setShowAlert(false)
            setIsBtnDisabled(true)
            const token = await user.getIdToken()
            dispatch(updateBooking(booking.companyEmail, 
                user.email,
                'CANCELLED',
                booking.companyName,
                booking.branch,
                null,
                null,
                null,
                token))
        } catch (error) {
            setIsBtnDisabled(false)
        }
    }

    const handleOnSelect = (e) => {
        const { name } = e.target
        setChecked(name)
    }

  return (
    <Wrapper style={{ position: 'relative' }}>
        {
            booking ? (
                <Booking>
                    <Heading>BOOKING <Highlighter>DETAILS</Highlighter></Heading>
                <BookingContainer >
                <Section className="booking__block">
                    <Subtitle>PERSONAL DETAILS</Subtitle>
                    <DetailItem>
                            <Field>Full Name:</Field>
                            <p>{booking.fullName}</p>
                    </DetailItem>
                    <DetailItem>
                            <Field>Mobile:</Field>
                            <p>{booking.phone}</p>
                    </DetailItem>
                    <DetailItem>
                            <Field>Address:</Field>
                            <p>{booking.homeAddress}</p>
                    </DetailItem>
                </Section>
                <Section className="booking__block">
                    <Subtitle>SERVICE DETAILS</Subtitle>
                    <DetailItem>
                            <Field>Status:</Field>
                            <p style={{
                                padding: 5,
                                color: 'white', 
                                borderRadius: 5,
                            ...(booking.state === 'COMPLETED' && { backgroundColor: 'green' }),
                            ...(booking.state === 'INPROGRESS' && { backgroundColor: 'blue' }), 
                            ...(booking.state === 'PENDING' && { backgroundColor: 'orange' }),
                            ...(booking.state === 'CANCELLED' && { backgroundColor: 'red' })}}>{booking.state}</p>
                    </DetailItem>
                    <DetailItem>
                            <Field>Appointment Date:</Field>
                            <p>{booking.dateTime}</p>
                    </DetailItem>
                    <DetailItem>
                            <Field>Service Tier:</Field>
                            <p>{booking.tier}</p>
                    </DetailItem>
                    <DetailItem>
                            <Field>Branch:</Field>
                            <p>{booking.branch}</p>
                    </DetailItem>
                </Section>
                
                <Section className="booking__block">
                    <Subtitle>VEHICLE DETAILS</Subtitle>
                    <DetailItem>
                            <Field>Make:</Field>
                            <p>{booking.make}</p>
                    </DetailItem>
                    <DetailItem>
                            <Field>Model:</Field>
                            <p>{booking.model}</p>
                    </DetailItem>
                    <DetailItem>
                            <Field>VIN:</Field>
                            <p>{booking.vinNumber}</p>
                    </DetailItem>
                    <DetailItem>
                            <Field>Year:</Field>
                            <p>{booking.modelYear}</p>
                    </DetailItem>
                    <DetailItem>
                            <Field>Mileage:</Field>
                            <p>{booking.mileage}</p>
                    </DetailItem>
                </Section>
                {
                        booking.carSummary ? (
                            <Section className="booking__block">
                                <Subtitle>VEHICLE SUMMARY</Subtitle>
                                <DetailItem>   
                                    <CarSummary>{booking.carSummary}</CarSummary>
                                </DetailItem>
                            </Section>
                        ): null
                    }
                {
                    amount ? (
                        <Section className="booking__block" >
                            <Subtitle>PAYMENT DETAILS</Subtitle>
                            <DetailItem>
                                    <Field>Amount:</Field>
                                    <p>{amount}</p>
                            </DetailItem>
                            <DetailItem>
                                    <Field>Payment Method:</Field>
                                    <p>{paymentOption}</p>
                            </DetailItem>
                            <DetailItem>
                                    <Field>Transaction Reference:</Field>
                                    <p>{transactionReference}</p>
                            </DetailItem>
                            <DetailItem>
                                    <Field>Bank Reference:</Field>
                                    <p>{bankReference}</p>
                            </DetailItem>
                            {
                             paymentOption === 'CARD PAYMENT' ? (
                            <PaymentOptions>
                                <Field>Select Payment Option:</Field>
                                    <div>
                                    <div className='booking__radio-option'>
                                    <input type="radio" 
                                        value='Ozow EFT'
                                        name='ozow'
                                        id='ozow'
                                        onChange={handleOnSelect}  
                                        checked={checked === 'ozow'}
                                    />
                                    <div>
                                        <label htmlFor='ozow'>OZOW EFT</label>
                                        <p style={{ color: 'blue', fontSize: 'x-small' }}>You need your Bank Account Username and Password</p>
                                    </div>
                                </div>
                                <div className='booking__radio-option'>
                                    <input type="radio" 
                                        value='Bank Card'
                                        name='card'
                                        id='card'
                                        checked={checked === 'card'} 
                                        onChange={handleOnSelect}
                                    />
                                    <div>
                                        <label htmlFor='card'>Bank Card</label>
                                        <p style={{ color: 'blue', fontSize: 'x-small' }}>We Only accept Mastercard and VISA</p>
                                    </div>
                                </div>
                                    </div>
                            </PaymentOptions>
                            ): null }
                        </Section>
                    ) : null
                }
            </BookingContainer>
                { updating ?  <Uploading text="updating booking" color={'black'}/>  : 
                    bookingUpdateError ? <p className='error'>Error updating booking</p>: 
                    isBookingUpdated ?  <Success>Booking Updated Successfully</Success>: 
                    transaction.success ? <Success>Please wait...</Success>: null }
            { !isBookingUpdated ? <ActionsContainer> 
                        {
                           verifyToken.verifying ? <Button background='black' 
                                                            color='white'
                                                            borderRadius="5px"
                                                            disabled={true}
                                                            >
                                                            Verifying Authentication Token...
                                                    </Button>  :
                           verifyToken.verificationError ? 
                           <Error>Token Expired. Request another link and verify within 15 minutes.</Error> :
                           verifyToken.verified ? <ActionButton>
                                {
                                    booking.state === 'COMPLETED' ? <MdOutlineShoppingCartCheckout color='white' size={15} />:
                                    <MdLogin color='white' size={15} />
                                }
                                <Button background='#009dfe' 
                                        color='white'
                                        borderRadius="20px"
                                        border='none'
                                        disabled={booking.state === 'COMPLETED' && paymentOption === 'CARD PAYMENT' ? (isBtnDisabled || !checked): isBtnDisabled}
                                        onClick={() => setIsPromptOpen(true)}
                                        >
                                            
                                        {
                                            booking.state === 'PENDING' ? 'Check In My Vehicle' : "Check Out My Vehicle"
                                            
                                        }
                                </Button>
                           </ActionButton> : booking.state === 'PENDING' ? <Button background='white' 
                                    color='red'
                                    borderRadius="5px"
                                    disabled={isBtnDisabled}
                                    onClick={() => setShowAlert(true)}
                                    >
                                    CANCEL BOOKING
                            </Button>: null }
                </ActionsContainer>: null }
                <Block>placeholder</Block>
        </Booking>
            ) : loading ? <BookingDetailSkeleton /> : <NoBookingFound />
        }
        {
        isModalOpen ? (
          <InteractiveModal height='120vh' setIsModalOpen={setIsModalOpen} onClose={onClose} style={{ position: 'absolute', width: '100%', bottom: 0 }}>
            <Wrapper>
            <div style={{ fontWeight: 700, textAlign: 'center', marginBottom: '30px' }}>Add a Review</div>
            <StarRatings
                            rating={rating}
                            starRatedColor="#ffa534"
                            starDimension="20px"
                            starSpacing="3px"
                            changeRating={ratingCompleted}
                            numberOfStars={5}
                            name='rating'
                        />
                <form onSubmit={submitReview}>
                      <textarea rows="4"
                                cols="45" 
                              onChange={handleOnChange}
                              value={review} 
                              placeholder='Review'
                              className='imodal__input'
                              style={{ width: '100%' }}
                      />
                      {missingReview ? <p style={{ textAlign: 'center', color: 'red'}}>Add review before Submission</p>: null}
                  </form>
                  <BtnContainer>
                      <FormButton background='#5282BD' 
                                  color='white' 
                                  disabled={isRevBtnDisabled}
                                  onClick={submitReview}>
                                  Create
                      </FormButton>
                      <FormButton borderRadius='5px' 
                                    disabled={isRevBtnDisabled}
                                  onClick={() => {
                                    setIsModalOpen(false)
                                    checkout()
                                  }}>
                                      Checkout Without Review
                      </FormButton>
                  </BtnContainer>
                  { updating ?  <Uploading text="updating booking" color={'black'}/>  : 
                  bookingUpdateError ? <p className='error'>Error updating booking</p>: 
                  isBookingUpdated ?  <Success>Booking updated successfully</Success>: null }                    
                { creating ?  <Success>Creating Review..</Success>  : 
                  createReviewError ? <p className='error'>Error creating review</p>: 
                  created ?  <Success>Review created successfully</Success>: null }
            </Wrapper>
          </InteractiveModal>
        ): showAlert ? (
            <InteractiveModal height='120vh'>
              <Wrapper>
              <div style={{ fontWeight: 700, textAlign: 'center', marginBottom: '30px' }}>Confirm Booking Cancellation</div>
              <p style={{ paddingBottom: 10 }}>This action will create a CANCEL request to your Service Provider. Your Booking will be deleted once they grant that request.</p>
                    <BtnContainer>
                        <FormButton  
                                    onClick={() => setShowAlert(false)}>
                                    Cancel
                        </FormButton>
                        <FormButton borderRadius='5px' 
                                    color='red'
                                    onClick={handleCancelBooking}>
                                        Confirm
                        </FormButton>
                    </BtnContainer>
              </Wrapper>
            </InteractiveModal>
          ): isPromptOpen ? <InteractiveModal height='120vh'>
          <Wrapper>
          <div style={{ fontWeight: 700, textAlign: 'center', marginBottom: '30px' }}>Confirm {booking.state === 'PENDING' ? 'Checkin': 'Checkout'}</div>
          <p style={{ paddingBottom: 10 }}>Continue with {booking.state === 'PENDING' ? 'Checkin': 'Checkout'}</p>
                <BtnContainer>
                    <FormButton  
                                onClick={() => setIsPromptOpen(false)}
                                color='red'>
                                Cancel
                    </FormButton>
                    <FormButton borderRadius='5px' 
                                color='green'
                                onClick={handleBookingUpdate}>
                                    Continue
                    </FormButton>
                </BtnContainer>
          </Wrapper>
        </InteractiveModal> : null
      }
      <Helmet>
                            <title>View Booking</title>
                            <meta name='description' content='View Booking' />
                        </Helmet>
    </Wrapper>
   
  )
}

export default BookingDetail