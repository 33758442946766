import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../../redux/axios/axios'

const initialState = {
  loading : false,
  bankingDetails: {},
  bankingDetailsError: ''
}

const bankingDetails = createSlice({
  name: 'bankingDetails',
  initialState,
  reducers: {
    fetchedBankingDetailsRequest(state) {
      state.loading = true
    },
    fetchedBankingDetailsSuccess(state, action) {
        state.loading = false
        state.bankingDetails = action.payload
    },
    fetchedBankingDetailsError(state, action) {
      state.loading = false
      state.bankingDetailsError = action.payload
      state.bankingDetails = null
    },
    fetchedBankingDetailsReset(state) {
      state.loading = false
      state.bankingDetailsError = ''
      state.bankingDetails = null
    },
  }
})

export const { fetchedBankingDetailsRequest, fetchedBankingDetailsSuccess, fetchedBankingDetailsError, fetchedBankingDetailsReset } = bankingDetails.actions

export const fetchBankingDetails = (email, token) => async (dispatch) => {
  dispatch(fetchedBankingDetailsRequest())
  try {
      const { data: { Items, Count }} = await customAxios.post('/paystack/bank-details', { email },{
        headers: {
            Authorization: `Bearer ${token}`
         }
     });
      !Count && dispatch(fetchedBankingDetailsSuccess(null))
      if (Count) {
        dispatch(fetchedBankingDetailsSuccess(Items[0]))
      }
  } catch (error) {
      dispatch(fetchedBankingDetailsError(error.message))
  }      
}

export const bankingDetailsReducer = bankingDetails.reducer
