import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect'

const PasswordStrengthMeter = ({password}) => {
    const [score, setScore] = useState(0)
    
    useEffect(() => {
      import("zxcvbn").then(({default: zxcvbn}) => {
        const { score } = zxcvbn(password);
        setScore(score);
      });
    },[password])

    const createPasswordLabel = (score) => {
      switch (score) {
        case 2:
          return 'Fair';
        case 3:
          return 'Good';
        case 4:
          return 'Strong';
        default:
          return 'Weak';
        }
    }

    return (
      <div style={{textAlign:'right'}}>
        <div style={{marginLeft:'auto'}} className='progress'>
          <div className={`${score >= 1 ? 'progress__weak': ''}`}></div>
          <div className={`${score >= 2 ? 'progress__fair': ''}`}></div>
          <div className={`${score >= 3 ? 'progress__good': ''}`}></div>
          <div className={`${score >= 4 ? 'progress__strong': ''}`}></div>
        </div>
        {password && <p style={{fontSize:'small', ...(isMobile && { color: 'white' })}}><strong style={{ color: isMobile ? 'white': 'black' }}>Password strength:</strong> {createPasswordLabel(score)}</p>}
      </div>
    ) 
}

export default PasswordStrengthMeter